import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import AboutSidebar from'./AboutSidebar'
import { Link } from 'react-router-dom'
const SocietyPilar = () => {
   return (
    <>
<Header/>
   <div className="inner_slider">
   <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
   <div className="row bread_cumb">
   <h2>Society Of Pilar</h2>
   <div className="theme-heading">
   <span className="colorW marB10"><i className="bi bi-building"></i></span>
   </div>
   <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> About Us <i className="bi bi-arrow-right"></i> Society Of Pilar</span></h5>
   </div>
   
   </div>
   <div className="innerpage">
<div className="row inner_cont">
  <div className="col-lg-9 col-md-8 col-sm-12 col-12">
  <p className="large_font">The Society of Pilar, an Indian Christian organization was founded in Goa in 1887 by Fr. Bento Martins, with the sole aim of sharing the Gospel of true human well-being as proclaimed by Jesus Christ. Initially, the activity of its members was limited to Goa and its surrounding areas. However, in 1939, the Society received a new thrust and vitality, and from then on, its members ventured to work far and wide in different parts of India, zealously spreading the Gospel that Jesus lived and died for.
  </p>
  <p className="large_font">
  One of the focuses of the Society's work has been in the field of education. Today the Society of Pilar has about 60 Residential units and counts about 400 odd members working in several parts of India from Jammu in the north to Chennai in the south, from Gujarat and Mumbai in the west to far-flung Andaman Islands in the east and even abroad. The Society runs schools and Colleges and several Hostels and Orphanages, besides other social welfare activities. The Delhi Province of the Society of Pilar, of which St. Xavier's Sirsa is a part, has ten residential units and about forty members.</p>
  <div className="clr"></div>
      <div className="row">
  <div className="col-lg-6 col-md-6 col-sm-6 col-6"><center>
      <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/society.jpg" alt="St. Xaviers Bathinda" className="sec_imgaes_new img-fluid" />
      </center></div>
  <div className="col-lg-6 col-md-6 col-sm-6 col-6"><center><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/society1.jpg"   alt="St. Xaviers Bathinda" className="sec_imgaes_new img-fluid" /></center></div>
          </div>
  <div className="clr"></div>
  </div>

<AboutSidebar/>
</div>
</div>
 <Footer/>
  </>
  )
}

export default SocietyPilar
