import React from 'react'
import { NavLink } from 'react-router-dom'
const AboutSidebar = () => {
  return (
    <>
    <div className="col-lg-3 col-md-4 col-sm-12 col-12">
    <div className="inner_menu">
    <h3>Menu Choice</h3>
    <ul id="sidebar">
       <li><NavLink to="/SocietyPilar">Society Of Pilar</NavLink></li>
       <li><NavLink to="/AboutSchool">About School</NavLink></li>
       <li><NavLink to="/Vision">Vision</NavLink></li>
       <li><NavLink to="/Mission">Mission</NavLink></li>
       <li><NavLink to="/Goals">Goals</NavLink></li>
       <li><NavLink to="/OurPatron">Our Patron</NavLink></li> 
       <li><NavLink to="/PrayerHymns">Prayer &amp; Hymns</NavLink></li> 
       <li><NavLink to="/Faculty">Faculty</NavLink></li> 
       <li><NavLink to="/OurStrength">Our Strength</NavLink></li>
       <li><NavLink to="/Infrastructure">Infrastructure</NavLink></li>
    </ul>
    <div className="clr"></div>
    </div>
    <div className="important_menu">
    <h3>Important Menu</h3>
    <ul>
    <li><NavLink to="/VirtualTour"><i className="fas fa-camera" aria-hidden="true"></i> 360<sup>o</sup></NavLink></li>
    <li><NavLink to="/ParentPortal"><i className="fa fa-newspaper" aria-hidden="true"></i> Portal Help?</NavLink></li>
    <li><NavLink to="/ErpSoftware"><i className="fas fa-desktop" aria-hidden="true"></i> ERP Software</NavLink></li>
    <li><NavLink to="/Junior/Default"><i className="fas fa-child" aria-hidden="true"></i> Kid's Corner</NavLink></li>
    <li><NavLink to="/Achievements"><i className="fa fa-trophy" aria-hidden="true"></i> Achievements</NavLink></li>
    <li><NavLink to="/Speech"><i className="fa fa-microphone" aria-hidden="true"></i> Speech</NavLink></li>
    </ul>
    <div className="clr"></div>
    </div>
    
    </div>
    </>
  )
}

export default AboutSidebar
