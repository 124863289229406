import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import { Link } from 'react-router-dom'
const SchoolParliament = () => {
    var myfun = function(ele){
        let offsetTop  = document.getElementById(ele).offsetTop;
        var right = document.getElementById("#right");
        window.scrollTo({
            top: offsetTop-100, 
            behavior: "smooth"
        });
    }
   return (
    <>
<Header/>   <div className="inner_slider">
   <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
   <div className="row bread_cumb">
   <h2>School Parliament </h2>
   <div className="theme-heading">
   <span className="colorW marB10"><i className="bi bi-building"></i></span>
   </div>
   <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span>  Office Bearers  <i className="bi bi-arrow-right"></i> School Parliament </span></h5>
   </div>
   </div>
   <div className="innerpage">
   <div className="row inner_cont schoolparliament">
   <ul className="linktosec">
   <li><button onClick={() => myfun('div2')}>Senior Cabinet</button></li>
   <li><button onClick={() => myfun('div8')}>Junior Cabinet</button></li>
   </ul>
   <div className="col-lg-12 col-md-12 col-sm-12 col-12 cabintes_sec">
   <h2>Formal Address by Office Bearers</h2>
   <div id="right" className="right">
   <div className="sx_msg_sec" id="div2">
   <div className="row">
   <div className="col-lg-12 col-md-12 col-sm-12 col-12 sx_msg_sec">
   <article>
   <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/1.Snehdeep Kaur - Prime Minister.jpg" alt="St. Xavier’s School Bathinda" className="sxumn_2021_msg img-fluid"/>
  <p><b>‘A GENUINE LEADER IS NOT A SEARCHER FOR CONSENSUS, BUT A MOULDER OF CONSENSUS’</b></p>
 <p> It is a great honour for me, the Prime Minister of very prestigious and bona-fide academic temple, St. Xavier’s, to have been vouchsafed with this momentous responsibility for academic year 2024-25. I express my heartfelt gratitude towards the enlightening and indwelling visions of our school, Principal Rev. Fr. Cidloy Furtado, all the untiring teachers and all my peers who put trust in my ability to lead and produce results.</p>
 
 <p> It indeed is a great rapture to carry out the assigned responsibilities in a sincere,Systematic and dedicated manner and to work persistently for the excellence of our school. I vow to leave no stone unturned to make our school scale the heights of success. I pray to Almighty for his rich blessings on all the Xavierites who make this institution shine brighter. </p>
 
 <p> In concise of words, I remember rightly said words by James Russell Lowell,</p>
 <p>‘GREATLY BEGIN! THOUGH YOU HAVE TIME <br/>
 BUT FOR A LINE, BE THAT SUBLIME, <br/>
 NOT FAILURE , BUT LOW AIM, IS CRIME’</p>
   <p><strong>SNEHDEEP KAUR BRAR</strong></p>
   <p><strong>(Prime Minister - XI D)</strong></p>
   <div className="clr"></div>
   </article>
   </div>
   <div className="col-lg-12 col-md-12 col-sm-12 col-12 sx_msg_sec">
   <article>
   <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/2.Allamveer Singh Dhillon - Deputy Prime Minister.JPG" alt="St. Xavier’s School Bathinda" className="sxumn_2021_msg img-fluid"/>
<p>The very essence of leadership is that you have to have vision. You cant blow an uncertain trumpet . </p>
<p>I Allamveer Singh Dhillon feel exceedingly blessed to have been given this golden opportunity to be the deputy Prime Minister of this prestigious institution. </p>
<p>I would like to extend my vote of thanks to our principal Rev. Fr Cidloy furtado and all the teachers who entrusted me with this position for academic year 2024-25. </p>
<p>I believe that being a leader isn’t just holding the position but it is linked to commitment , determination , teamwork , loyalty , and earning respect. It is the act of leading and guiding a team in a manner that the success of one is directly linked to the success of the team. </p>
<p>As it is rightly said by thomas Jefferson `In matters of style, swim with the current. In matters of principle, stand like a rock.’ </p>
<p>I pray to almighty to strengthen and bless all  the Xavierites to achieve the heights of success. </p>
   <p><strong>Allamveer Singh Dhillon </strong></p>
   <p><strong>(Deputy Prime Minister - XI B )</strong></p>
   <div className="clr"></div>
   </article>
   </div>
   <div className="col-lg-12col-md-12 col-sm-12 col-12 sx_msg_sec">
   <article>
   <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/3.Deevansh Brar - Head Girl.JPG" alt="St. Xavier’s School Bathinda" className="sxumn_2021_msg img-fluid"/>
    <p>In the heart of every leader lies the power to uplift, encourage, and ignite the potential in others. </p>
    <p>As the Head Girl of this esteemed institution I am deeply grateful for this opportunity to serve and deeply appreciative of the trust placed in me "As we express our gratitude, we must never forget that the highest appreciation is not to utter words, but to live by them." I am deeply thankful to our esteemed Principal, dedicated teachers, supportive fellow students, and hardworking staff for your trust. </p>
    <p>True leaders inspire belief in themselves, demonstrating that leadership is defined not by titles, but by compassion and genuine connection. A leader’s impact is measured by their ability to uplift, encourage, and ignite the potential in others. This responsibility is one I embrace wholeheartedly.
     </p><p> I hold our school motto: "Learning Hearts and Daring Minds," dear to my heart cultivating both compassionate learners and bold thinkers along with fostering both intellectual growth and courageous spirits in me and my fellow schoolmates. </p>
    <p>I will be the reason someone feels welcomed, appreciated, and valued. Remember, the sign of a beautiful person is they see beauty in others. A candle loses nothing by lighting another candle. By embodying these principles, I promise this year truly impactful, nurturing hearts that embrace knowledge and minds that dare to innovate. </p>
    <p>In the spirit of our shared goals, I promise to brighten this year with the same strength and brilliance as the hardest of diamonds, reflecting our commitment to overcoming challenges and shining brightly in all our endeavours and remembering the trust placed in me. </p>
   <p><strong>Deevansh Brar</strong></p>
   <p><strong>(Head Girl - VIII E )</strong></p>
   <div className="clr"></div>
   </article>
   </div>
   <div className="col-lg-12 col-md-12 col-sm-12 col-12 sx_msg_sec">
   <article>
   <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/4.Teghpartap Singh - Head Boy.JPG" alt="St. Xavier’s School Bathinda" className="sxumn_2021_msg img-fluid"/>
     <p>Ralph Waldo Emerson once said, "The only way to have a friend is to be one." This philosophy will guide my approach to leadership. </p>
    <p>I'm incredibly thankful to our visionary Father Principal, inspiring teachers, supportive classmates,and dedicated staff for entrusting me with this responsibility. I promise to carry out my duties with unwavering commitment and integrity. </p>
    <p>I promise to be a voice for our student body, champion our school's ethos, and foster a spirit of unity and growth. Together, we'll breathe life into our school motto:  "Learning Hearts and Daring Minds," nurturing both empathy and courage within our community. </p>
    <p>Let's aspire to create an environment where everyone feels valued, heard, and empowered. </p>
    <p>Remember, true strength lies in lifting others up. By shining our light, we illuminate the path for others without diminishing our own brilliance. </p>
    <p>As we embark on this journey, let's kindle curiosity in our hearts and audacity in our minds. </p>
    <p>Let's be the reason someone feels welcomed, seen, and appreciated. Together, we can make this year one of remarkable achievements and lasting memories. </p>
   <p><strong>Teghpartap Singh </strong></p>
   <p><strong>(Head Boy  - IX B)</strong></p>
   <div className="clr"></div>
   </article>
   </div>
   
   
   </div>
   <div className="clr"></div>
   </div>
   <div className="cabi_2022 clr_1_cabi">
   <center><h2>Core Parliament </h2></center>
   <div className="row">
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
       <article>
            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/1.Snehdeep Kaur - XI D - Prime Minister.jpg"  alt="St. Xavies Bathinda" /><h3>PRIME MINISTER </h3><h4>Snehdeep Kaur - XI D </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
       <article>
            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/2.Allamveer Singh Dhillon - XI B - Deputy Prime Minister.JPG"  alt="St. Xavies Bathinda" /><h3>DEPUTY PRIME MINISTER </h3><h4>Allamveer Singh Dhillon - XI B</h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
       <article>
            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/3.Noor- XI C - Speaker.JPG"  alt="St. Xavies Bathinda" /><h3>SPEAKER </h3><h4>Noor- XI C  </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
       <article>
            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/4.Trigun Kaur Dhillon - XI D - Deputy Speaker.jpg"  alt="St. Xavies Bathinda" /><h3>DEPUTY SPEAKER </h3><h4>Trigun Kaur Dhillon - XI D</h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
       <article>
            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/5.Tanveer Brar- XI D - General Secretary.JPG"  alt="St. Xavies Bathinda" /><h3>GENERAL SECRETARY </h3><h4>Tanveer Brar- XI D</h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
       <article>
            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/6.Janvi - XI B - General Secretary.JPG"  alt="St. Xavies Bathinda" /><h3>GENERAL SECRETARY </h3><h4>Janvi - XI B</h4>
        </article>
    </div>
       <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
       <article>
            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/7.Jashmi Gupta - XI D - General Secretary.JPG"  alt="St. Xavies Bathinda" /><h3>GENERAL SECRETARY </h3><h4>Jashmi Gupta - XI D</h4>
        </article>
    </div>
   </div>
   </div>
   <div className="cabi_2022 clr_2_cabi">
   <center><h2>Editorial Board</h2></center>
   <div className="row">
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
       <article>
           <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/1.Namika - XI C - Editor (Hindi).JPG"  alt="St. Xavies Bathinda" /><h3>EDITORIAL BOARD (Hindi) </h3><h4>Namika - XI C </h4>
       </article>
   </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
       <article>
           <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/2.Taranjot Kaur - XI D - Editor (Punjabi).JPG"  alt="St. Xavies Bathinda" /><h3>EDITORIAL BOARD (Punjabi) </h3><h4>Taranjot Kaur - XI D </h4>
       </article>
   </div> 
   </div>
   </div>
   <div className="cabi_2022 clr_3_cabi">
   <center><h2>School Parliament</h2></center>
   <div className="row">
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/1.Deevnash Brar - VIII E - Head Girl.JPG"  alt="St. Xavies Bathinda" />
            <h3>HEAD GIRL </h3><h4>Deevansh Brar - VIII E</h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/2.Teghpartap Singh - IX B - Head Boy.JPG"  alt="St. Xavies Bathinda" />
            <h3>HEAD BOY</h3><h4>Teghpartap Singh - IX B </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/3.Ashika - IX E Minister of Cultural Affairs.JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTER OF CULTURAL AFFAIRS </h3><h4>Ashika - IX E </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/4.Rehmat - VIII E - Deputy Minister of Cultural Affairs.JPG"  alt="St. Xavies Bathinda" />
            <h3>DEPUTY MINISTER OF CULTURAL AFFAIRS </h3><h4>Rehmat - VIII E </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/5.Ansham Garg - IX E - Ministry of Cultural Affaairs (Secretary).JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF CULTURAL AFFAIRS (SECRETARY) </h3><h4>Ansham Garg - IX E </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/6.Kashvi - VIII E - Ministry oof Cultural Affairs (Member).JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF CULTURAL AFFAIRS (MEMBER) </h3><h4>Kashvi - VIII E </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/7.Tavish Goyal - IX C - Minister of Education.JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTER OF EDUCATION </h3><h4>Tavish Goyal - IX C</h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/8.Shrutkeerti Sankrityayan - VIII B - Deputy Minister of Education.JPG"  alt="St. Xavies Bathinda" />
            <h3>DEPUTY MINISTER OF EDUCATION</h3><h4>Shrutkeerti Sankrityayan - VIII B</h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/9.Amanat Preet Kaur - IX D - Ministry of Education (Secretary).JPG"  alt="St. Xavies Bathinda" />
            <h3> MINISTRY OF EDUCATION (MEMBER)</h3><h4>Amanat Preet Kaur - IX D</h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/10.Ishaan - VIII A - Ministry of Education (Member).JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF EDUCATION (MEMBER)</h3><h4>Ishaan - VIII A </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/11.Samarth Arora - VIII B - Ministry of Education (Member).JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF EDUCATION (MEMBER) </h3><h4>Samarth Arora - VIII B </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/12.Sehaj Sharma - IX B - Minister of Environment.JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTER OF ENVIRONMENT </h3><h4>Sehaj Sharma - IX B</h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/13.Vanshdeep Kaur - VIII A - Deputy Minister of Education.JPG"  alt="St. Xavies Bathinda" />
            <h3> DEPUTY MINISTER OF ENVIRONMENT </h3><h4>Vanshdeep Kaur - VIII A </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/14.Dheirya Singla - IX A - Ministry of Environment (Secretary).JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF ENVIRONMENT (SECRETARY) </h3><h4>Dheirya Singla - IX A </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/15.Iban Sharma - VIII D - Ministry of Environment (Member).JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF ENVIRONMENT (MEMBER) </h3><h4>Iban Sharma - VIII D</h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/16.Paizpreet Kaur - VIII A - Ministry of Environment (Member).JPG"  alt="St. Xavies Bathinda" />
            <h3>MINSTER OF EXTERNAL AFFAIRS</h3><h4>Paizpreet Kaur - VIII A </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/17.Ayaan Chanana - IX D - Minister of External Affairs.JPG"  alt="St. Xavies Bathinda" />
            <h3>DEPUTY MINISTER OF EXTERNAL AFFAIRS</h3><h4>Ayaan Chanana - IX D </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/18.Yamini - VIII A - Deputy Minister of External Affairs.JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF EXTERNAL AFFAIRS (SECRETARY)</h3><h4>Yamini - VIII A </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/19.Hasrat Kansal - IX D - Ministry of External Affairs (Secretary).JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF EXTERNAL AFAIRS (MEMBER)</h3><h4>Hasrat Kansal - IX D </h4>
        </article>
    </div>
   
   
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/20.Parneet Kaur - VIII B - Ministry of External Affairs (Member).JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTER OF FINANCE </h3><h4>Parneet Kaur - VIII B </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/21.Ayush Gupta - IX A - Minister of Finance.JPG"  alt="St. Xavies Bathinda" />
            <h3>DEPUTY MINISTER OF FINANCE </h3><h4>Ayush Gupta - IX A</h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/22.Avni - VIII B - Deputy Minitser of Finance.JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF FINANCE (SECRETARY) </h3><h4>Avni - VIII B </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/23.Oourav Sareen - VIII C - Ministry of Finance (Secretary).JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF FINANCE (MEMBER)</h3><h4>Oourav Sareen - VIII C </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/24.Amitesh - VIII B - Ministry of Finance (Member).JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF FINANCE (MEMBER)</h3><h4>Amitesh - VIII B </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/25.Aikasdeep Kaur Sidhu - IX D - Ministry of Finance (Member).JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTER OF HEALTH</h3><h4>Aikasdeep Kaur Sidhu - IX D </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/26.Aakarshi - IX D - Minister of Health.JPG"  alt="St. Xavies Bathinda" />
            <h3>DEPUTY MINISTER OF HEALTH</h3><h4>Aakarshi - IX D </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/27.Lovish Bansal - VIII D - Deputy Minister of Health.JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF HEALTH (SECRETARY)</h3><h4>Lovish Bansal - VIII D </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/28.Khushi Garg - IX D - Ministry of Health (Secretary).JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF HEALTH (MEMBER)</h3><h4>Khushi Garg - IX D </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/29.Kinjal - VIII D - Ministry of Health (Member).jpg"  alt="St. Xavies Bathinda" />
            <h3>MINISTER OF HOME AFFAIRS</h3><h4>Kinjal - VIII D </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/30.Gunaaz Kaur -  IX D - Minster of Home Affairs.JPG"  alt="St. Xavies Bathinda" />
            <h3>DEPUTY MINISTER OF HOME AFFAIRS</h3><h4>Gunaaz Kaur -  IX D</h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/31.Mannat Kaur Sidhu - VIII D - Deputy Minister of Home Affairs.JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF HOME AFFAIRS (SECRETARY)</h3><h4>Mannat Kaur Sidhu - VIII D</h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/32.Eshan Singh Sidhu - IX C - Ministry of Home Affairs (Secretary).JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF HOME AFFAIRS (MEMBER)</h3><h4>Eshan Singh Sidhu - IX C</h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/33.Hasratveer - IX D - Ministry of Home Affairs (Member).JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF HOME AFFAIRS (MEMBER)</h3><h4>Hasratveer - IX D</h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/34.Suhani Mittal - VIII A - Ministry of Home Affairs (Member).JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTER OF I.T</h3><h4>Suhani Mittal - VIII A </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/35.Prabhdeep Singh Sadeora - IX B - Minister of I.T.JPG"  alt="St. Xavies Bathinda" />
            <h3>DEPUTY MINISTER OF I.T</h3><h4>Prabhdeep Singh Sadeora - IX B</h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/36.Aaditya Bansal - VIII A - Deputy Minister of I.T.JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF I.T (SECRETARY) </h3><h4>Aaditya Bansal - VIII A </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/37.Aarav Jain - VIII A - Ministry of I.T (Secretary).JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF I.T (MEMBER)</h3><h4>Aarav Jain - VIII A</h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/38.Angel - VIII A - Ministry of I.T (Member).JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF I.T (MEMBER)</h3><h4>Angel - VIII A  </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/39.Ishpreet Singh - VIII B - Ministry of I.T (Member).JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTER OF INNOVATION </h3><h4>Ishpreet Singh - VIII B</h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/40.Ishmeet Kaur Jagdeo - IX C - Minister of Innovation.JPG"  alt="St. Xavies Bathinda" />
            <h3>DEPUTY MINISTER OF INNOVATION </h3><h4>Ishmeet Kaur Jagdeo - IX C</h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/41.Toshania - VIII A - Deputy Minister of Innovation.JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF INNOVATION (SECRETARY)  </h3><h4>Toshania - VIII A</h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/42.Ishika Garg - IX B - Ministry of Innovation (Secretary).JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF INNOVATION (MEMBER) </h3><h4>Ishika Garg - IX B</h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/43.Arnav Mittal - VIII D - Ministry of Innovation (Member).JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF INNOVATION (MEMBER) </h3><h4>Arnav Mittal - VIII D</h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/44.Charvi Bajaj - IX B - Minister of S.I.G.JPG"  alt="St. Xavies Bathinda" />
            <h3> MINISTER OF SI.G</h3><h4>Charvi Bajaj - IX B</h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/45.Nishad - VIII D - Deputy Minister of S.I.G.JPG"  alt="St. Xavies Bathinda" />
            <h3>DEPUTY MINISTER OF S.I.G </h3><h4>Nishad - VIII D </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/46.Shubhdeep Kaur - IX D - Minisstry of S.I.G (Secretary).JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF S.I.G (SECRETARY)</h3><h4>Shubhdeep Kaur - IX D  </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/47.Inayat - VIII A - Minisstry of S.I.G (Member).JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF S.I.G (MEMBER)</h3><h4>Inayat - VIII A </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/48.Areet Goyal - IX C - Minister of Social Welfare.JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTER OF SOCIAL WELFARE</h3><h4>Areet Goyal - IX C</h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/49.Kanishka - VIII  D - Deputy Minister of Social Welfare.JPG"  alt="St. Xavies Bathinda" />
            <h3>DEPUTY MINISTER OF SOCIAL WELFARE</h3><h4>Kanishka - VIII  D </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/50.Pranvi Aggarwal - IX E - Ministry of Social Welfare (Secretary).JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF SOCIAL WELFARE (SECRETARY)</h3><h4>Pranvi Aggarwal - IX E </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/51.Blessy Sodday - VIII D- Ministry of Social Welfare (Member).JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF SOCIAL WELFARE (MEMBER) </h3><h4>Blessy Sodday - VIII D </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/52.Ibarat Kaur - XI D - Minister of Sports.jpg"  alt="St. Xavies Bathinda" />
            <h3>MINISTER OF SPORTS</h3><h4>Ibarat Kaur - XI D </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/53.Kar Kamalpreer Singh - IX B - Deputy Minister of Sports.JPG"  alt="St. Xavies Bathinda" />
            <h3>DEPUTY MINISTER OF SPORTS </h3><h4>Kar Kamalpreer Singh - IX B </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/54.Ravneet Kaur - VIII B - Ministry of Sports (Secretary).JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF SPORTS (SECRETARY)</h3><h4>Ravneet Kaur - VIII B </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/55.Guragam Singh Sidhu - VIII E - Ministry of Sports (Member).JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF SPORTS (MEMBER) </h3><h4>Guragam Singh Sidhu - VIII E </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/56.Guntasveer Singh Sidhu - VIII E - Ministry of Sports (Member).JPG"  alt="St. Xavies Bathinda" />
            <h3>MINISTRY OF SPORTS (MEMBER) </h3><h4>Guntasveer Singh Sidhu - VIII E</h4>
        </article>
    </div> 
   </div>
   </div>
   <div className="cabi_2022 clr_5_cabi">
   <center><h2>School Cabinet</h2></center>
   <div className="row">
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/1.Jainish Khurana - IX C - Emerald House Captain.JPG"  alt="St. Xavies Bathinda" />
            <h3>EMERALD HOUSE CAPTAIN </h3><h4>Jainish Khurana - IX C </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/2.Parik Garg - IX C - Ruby House Captain.JPG"  alt="St. Xavies Bathinda" />
            <h3>RUBY HOUSE CAPTAIN </h3><h4>Parik Garg - IX C </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/3.Jainy Bansal - IX B - Sapphire House Captain.JPG"  alt="St. Xavies Bathinda" />
            <h3>SAPPHIRE HOUSE CAPTAIN </h3><h4>Jainy Bansal - IX B </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/4.Shrishty - IX E - Topaz House Captain.JPG"  alt="St. Xavies Bathinda" />
            <h3>TOPAZ HOUSE CAPTAIN </h3><h4>Shrishty - IX E</h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/5.Mantaj Singh Sidhu - VIII C - Emerald House Vice Captain.JPG"  alt="St. Xavies Bathinda" />
            <h3>EMERALD HOUSE VICE CAPTAIN </h3><h4>Mantaj Singh Sidhu - VIII C</h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/8.Araina - VIII A - Ruby House Vice Captain.JPG"  alt="St. Xavies Bathinda" />
            <h3>RUBY HOUSE VICE CAPTAIN </h3><h4>Araina - VIII A </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/9.Sargam Bansal - VIII A - Sapphire House Vice Captain.JPG"  alt="St. Xavies Bathinda" />
            <h3>SAPPHIRE HOUSE VICE CAPTAIN </h3><h4>Sargam Bansal - VIII A </h4>
        </article>
    </div>
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
        <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/10.Jitesh Bansal - VIII D - Topaz House Vice Captain.JPG"  alt="St. Xavies Bathinda" />
            <h3>TOPAZ HOUSE VICE CAPTAIN </h3><h4>Jitesh Bansal - VIII D</h4>
        </article>
    </div>
   </div>
   </div>

   <div className="cabi_2022 clr_5_cabi" id="div8">
   <center><h2>Junior School Cabinet</h2></center>
  <div className="row">
   <div className="col-lg-6 col-md-12 col-sm-12 col-12 sx_msg_sec">
   <article>
   <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/Meer Malhotra-HeadBoy.jpg" alt="St. Xavier’s School Bathinda" className="sxumn_2021_msg img-fluid"/>
   <h3>Message By Head Boy</h3>
   <p>  Being the head boy at St Xavier's School requires a lot of hard work, persistence and gratitude and that's exactly what motivates me even more.  With the help and cooperation of school authorities, teachers and my counterparts, I assure you that I will be able to live up to everyone's expectations not just today but throughout the year. Leaving no stone unturned, I will work hard to set a milestone for others to be followed in the years to come.</p>
   
   <p><strong> Meer Malhotra - III D</strong></p>
   <p><strong> Junior Head Boy</strong></p>
   
   <div className="clr"></div>
   </article>
   </div>
   
   <div className="col-lg-6 col-md-12 col-sm-12 col-12 sx_msg_sec">
   <article>
   <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/Amayra - Head Girl.jpg" alt="St. Xavier’s School Bathinda" className="sxumn_2021_msg img-fluid"/>
   <h3>Message By Head Girl</h3>
   <p> Being a leader, I assure you all to put best of my knowledge, hard-work and perseverance for the betterment of the students in order to keep our school in high spirits always. I will also make sure that I help all my friends in this school to unleash their hidden abilities to achieve their goals.</p>
   
   <p><strong>Amayra - III E   </strong></p>
   <p><strong>Junior Head Girl</strong></p>
   <div className="clr"></div>
   </article>
   </div>
   </div>
   
   <div className="row">
   
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
   <article>
   <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/Meer Malhotra-HeadBoy.jpg"  alt="St. Xavies Bathinda" />
   <h3>Junior Head Boy </h3>
   <h4> Meer Malhotra - III D </h4>
   </article>
   </div>
   
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
   <article>
   <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/Amayra - Head Girl.jpg"  alt="St. Xavies Bathinda" />
   <h3>Junior Head Girl</h3>
   <h4>Amayra - III E </h4>
   </article>
   </div>
   
   
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
   <article>
   <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/EMERALDHOUSE-CAPTAIN.jpg"  alt="St. Xavies Bathinda" />
   
   <h3>Captain  - Emerald House </h3>
   <h4>Gurjot Singh - III E   </h4>
   </article></div>
   
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
   <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/EmeraldHouse-ViceCaptain.jpg"  alt="St. Xavies Bathinda" />
   <h3>Vice Captain - Emerald House </h3>
   <h4>Arshiya - III C</h4>
   </article></div>
   
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
   <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/RubyHouse-Captain.jpg"  alt="St. Xavies Bathinda" />
   <h3>Captain - Ruby House </h3>
   <h4>Mannatveer Kaur Sidhu - III D  </h4>
   </article></div>
   
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
   <article><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/RubyHouse-viceCaptain.jpg"  alt="St. Xavies Bathinda" />
   <h3>Vice Captain -Ruby House</h3>
   <h4>Mahir Goyal - III A  </h4>
   </article></div>
   
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
   <article>
   <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/SapphireHouse-Captain.jpg"  alt="St. Xavies Bathinda" />
   <h3>Captain - Sapphire House</h3>
   <h4>Kiratbir Kaur   - III C</h4>
   </article></div>
   
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
   <article>
   <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/SapphireHouse-ViceCaptain.jpg"  alt="St. Xavies Bathinda" />
   <h3>Vice Captain - Sapphire House </h3>
   <h4>Mitansh Garg  - III B </h4>
   </article></div>
   
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
   <article>
   <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/TopazHouse-Captain.jpg"  alt="St. Xavies Bathinda" />
   <h3>Captain - Topaz House</h3>
   <h4>Pranavi Arora - III E</h4>
   </article></div>  
   
   
   <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
   <article>
   <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/TopazHouse-ViceCaptain.jpg"  alt="St. Xavies Bathinda" />
   <h3>Vice Captain - Topaz House </h3>
   <h4>Shanaya Nagpal - III D </h4>
   </article></div> 
   </div> 
   </div>
   </div>
</div>
</div>
</div> 
 <Footer/>
  </>
  )
}

export default SchoolParliament
