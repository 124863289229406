import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import ProspectSidebar from'./ProspectSidebar'
import { Link } from 'react-router-dom'
const WithdrawalDismissal = () => {
  return (
    <>
<Header/>
    <div className="inner_slider">
    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
    <div className="row bread_cumb">
    <h2>Withdrawal  Dismissal Rules </h2>
    <div className="theme-heading">
    <span className="colorW marB10"><i className="bi bi-building"></i></span>
    </div>
    <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span>  Prospectus <i className="bi bi-arrow-right"></i> Withdrawal  Dismissal Rules</span></h5>
    </div>
    
    </div>
    <div className="innerpage">
<div className="row inner_cont">
   <div className="col-lg-9 col-md-8 col-sm-12 col-12">
   <ul>
   <li>Parents or guardians wishing to withdraw their children or wards from school must notify their intention to the Principal in the form provided in the school diary. The transfer certificate will be issued within 7 days after such notice.</li>
   <li>One calendar month’s notice will have to be given for the withdrawal of the child from the school. However, full fee for the quarter will be charged.</li>
   <li>All dues must be cleared and books and other school property items returned, before the child is withdrawn and transfer certificate obtained.</li>
   <li>Duplicate copies of T.C. may be issued on payment of Rs. 50/-.</li>
   <li>The management reserves to itself the right to dismiss any student.</li>
   <li>The Principal is empowered to effect the withdrawal of a pupil or to remove the name of the pupil from the rolls of the school if, in his opinion, the progress of the pupil or conduct is dissatisfactory or whose parents/guardians show no interest in the progress of their wards. Immorality, insubordination, contempt of wilful damage of property and physical assault is always enough reason for immediate dismissal. Prolonged absence which is not adequately justified or explained, may be considered reason enough for dismissal.</li>
   <li>The management may require the withdrawal of any student when satisfied that such a step is necessary in the interest of the school.</li>
   <li>Any pupil taking tuition from St. Xavier's School teacher before seeking the written permission from the Principal, will be dismissed.</li>
   <li>Serious violation of the rules, regulations and the discipline of the school is punishable including expulsion from school.</li>
   <li>Immediate expulsion or even rustication will follow, if the continued presence of the defaulting student is damaging the physical and moral well-being of other students of the school, or if the violation is punishable seriously at the board level.</li>
   <li>A student involved in any incident even outside the school which brings discredit to the Institution, either because of unpleasant nature or inviting criminal or legal action by police or the court will be dismissed from school without enquiry.</li>
   <li>A student found in the possession of material for unfair means during the examination will be dismissed.</li>
   </ul>
   <div className="clr"></div>
       <div className="row">
   <div className="col-lg-6 col-md-6 col-sm-6 col-6"><center><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/about.jpg" alt="St. Xaviers Bathinda" className="sec_imgaes_new img-fluid" /></center></div>
   <div className="col-lg-6 col-md-6 col-sm-6 col-6"><center><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/about1.jpg" alt="St. Xaviers Bathinda" className="sec_imgaes_new img-fluid" /></center></div>
           </div>
   <div className="clr"></div>
   </div>

<ProspectSidebar/>

</div>
</div>
  <Footer/>
  </>
  )
}

export default WithdrawalDismissal
