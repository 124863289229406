import React from 'react'
import { NavLink } from 'react-router-dom'
const MessageSidebar = () => {
  return (
   <>
   <div className="col-lg-3 col-md-4 col-sm-12 col-12">
   <div className="inner_menu">
   <h3>Menu Choice</h3>
   <ul id="sidebar">
   <li><NavLink id="A2" to="/PresidentMessage">President</NavLink></li>
   <li><NavLink id="A4" to="/ManagerMessage">Manager </NavLink></li>
   <li><NavLink id="A6" to="/PrincipalMessage">Principal</NavLink></li>
   <li><NavLink to="/SeniorWingCoordinatorMessage">Coordinator's Message (Senior Wing)</NavLink></li>
   <li><NavLink to="/SupervisorMessage">Supervisor's Message (Senior Wing)</NavLink></li>
   <li><NavLink to="/JuniorWingCoordinatorMessage">Coordinator's Message (Junior Wing)</NavLink></li>
   </ul>
   <div className="clr"></div>
   </div>
   <div className="important_menu">
   <h3>Important Menu</h3>
     <ul>
       <li><NavLink to="/VirtualTour"><i className="fas fa-camera" aria-hidden="true"></i> 360<sup>o</sup></NavLink></li>
       <li><NavLink to="/ParentPortal"><i className="fa fa-newspaper" aria-hidden="true"></i> Portal Help?</NavLink></li>
       <li><NavLink to="/ErpSoftware"><i className="fas fa-desktop" aria-hidden="true"></i> ERP Software</NavLink></li>
       <li><NavLink to="/Junior/Default"><i className="fas fa-child" aria-hidden="true"></i> Kid's Corner</NavLink></li>
       <li><NavLink to="/Achievements"><i className="fa fa-trophy" aria-hidden="true"></i> Achievements</NavLink></li>
       <li><NavLink to="/Speech"><i className="fa fa-microphone" aria-hidden="true"></i> Speech</NavLink></li>
       </ul>
   <div className="clr"></div>
   </div>
   
   </div>
   </>
  )
}

export default MessageSidebar
