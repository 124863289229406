import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import AboutSidebar from'./AboutSidebar'
import { Link } from 'react-router-dom'
const Mission = () => {
   return (
    <>
<Header/>   <div className="inner_slider">
   <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
   <div className="row bread_cumb">
   <h2>Mission</h2>
   <div className="theme-heading">
   <span className="colorW marB10"><i className="bi bi-building"></i></span>
   </div>
   <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> About Us <i className="bi bi-arrow-right"></i> Mission</span></h5>
   </div>
   
   </div>
   <div className="innerpage">
<div className="row inner_cont">
  <div className="col-lg-9 col-md-8 col-sm-12 col-12">
  <p>St. Xavier's School Bathinda is a minority institution with the mission of imparting all round integral formation and education to children in order to make them grow into honest, upright and dedicated citizens of India. The mission is categorised into various areas:</p>
  
  <p><strong>1. Value Education</strong></p>
  <ul>
  <li>inculcating positive values of fraternity, love, harmony, and compassion.</li>
  <li>Forming hearts and minds with love for one's neighbour without distinction of Caste, Creed, Race or Religion.</li>
  <li>imparting value education for character formation based on Indian Ethos as well as Universal perspectives.</li>
  </ul>
  <p><strong>2. Academic Excellence</strong></p>
  <ul>
  <li>Fostering creativity and ability to reason, analyse and think</li>
  <li>Providing facilities for acquiring knowledge of Sciences, Arts and Allied studies</li>
  </ul>
  <p><strong>3. Sports and balanced physical development</strong></p>
  <ul>
  <li>Developing Healthy habits of exercise and upkeep of the physique</li>
  <li>Inducing Students to develop an alert mind in a healthy body.</li>
  </ul>
  <p><strong>4. Spiritual growth</strong></p>
  <ul>
  <li>Enabling Students to find spiritual values in their own religion</li>
  <li>- Encouraging the search for spiritual enlightenment</li>
  </ul>
  <p><strong>5. Emotional Maturity</strong></p>
  <ul>
  <li>Creation of healthy attitudes in students to face contemporary situations</li>
  <li>Promotion of maturity to deal with challenged, difficulties and hazards.</li>
  </ul>
  <div className="clr"></div>
  </div>
<AboutSidebar/>
</div>
</div>
 <Footer/>
  </>
  )
}

export default Mission
