
import { BrowserRouter,Routes,Route } from "react-router-dom";
import Home from "./Pages/Home";
import AboutSchool from'./Pages/AboutSchool'
import SocietyPilar from'./Pages/SocietyPilar'
import Vision from'./Pages/Vision'
import Mission from'./Pages/Mission'
import Goals from'./Pages/Goals'
import OurPatron from'./Pages/OurPatron'
import PrayerHymns from'./Pages/PrayerHymns'
import Faculty from'./Pages/Faculty'
import OurStrength from'./Pages/OurStrength'
import Infrastructure from'./Pages/Infrastructure'
import Curriculum from'./Pages/Curriculum'
import SchoolTimings from'./Pages/SchoolTimings'
import ParentCooperation from'./Pages/ParentCooperation'
import AdmissionWithdrawal from'./Pages/AdmissionWithdrawal'
import AdmissionRules from'./Pages/AdmissionRules'
import ProcessAdmission from'./Pages/ProcessAdmission'
import FeeRules from'./Pages/FeeRules'
import WithdrawalDismissal from'./Pages/WithdrawalDismissal'
import AssessmentRules from'./Pages/AssessmentRules'
import LibraryRules from'./Pages/LibraryRules'
import Attendance from'./Pages/Attendance'
import Tc from'./Pages/Tc'
import BookList from'./Pages/BookList'
import PresidentMessage from'./Pages/PresidentMessage'
import ManagerMessage from'./Pages/ManagerMessage'
import PrincipalMessage from'./Pages/PrincipalMessage'
import SeniorWingCoordinatorMessage from'./Pages/SeniorWingCoordinatorMessage'
import SupervisorMessage from'./Pages/SupervisorMessage'
import JuniorWingCoordinatorMessage from'./Pages/JuniorWingCoordinatorMessage'
import Highlights from'./Pages/Highlights'
import Teachers from'./Pages/Teachers'
import Students from'./Pages/Students'
import Magazine from'./Pages/Magazine'
import PhotoGallery from'./Pages/PhotoGallery'
import VideoGallery from'./Pages/VideoGallery'
import Memories from'./Pages/Memories'
import MediaGallery from'./Pages/MediaGallery'
import VirtualTour from'./Pages/VirtualTour'
import VoiceSms from'./Pages/VoiceSms'
import NccArmy from'./Pages/NccArmy'
import SocialOutreach from'./Pages/SocialOutreach'
import NccNavy from'./Pages/NccNavy'
import ManagementCommittee from'./Pages/ManagementCommittee'
import SchoolParliament from'./Pages/SchoolParliament'
import SxmunInformation from'./Pages/SxmunInformation'
import Luminaire from'./Pages/Luminaire'
import Career from'./Pages/Career'
import ErpSoftware from'./Pages/ErpSoftware'
import ParentPortal from'./Pages/ParentPortal'
import Speech from'./Pages/Speech'
import AlumniTestimonials from'./Pages/AlumniTestimonials'
import ParentTestimonial from'./Pages/ParentTestimonial'
// import PhotoCategories from'./Pages/PhotoCategories' 
import NewsLetter from'./Pages/NewsLetter'
import Circular from'./Pages/Circular'
import Toppers from'./Pages/Toppers'
import ToppersXII from'./Pages/ToppersXII'
import Reminiscence from'./Pages/Reminiscence'
import Alumni from'./Pages/Alumni'
import Events from'./Pages/Events'
import News from'./Pages/News'
import Achievements from'./Pages/Achievements'
import Committee from'./Pages/Committee'
import SchoolUniform from'./Pages/SchoolUniform'
import JuniorHome from'./Junior/Pages/JuniorHome'
import JuniorNews from'./Junior/Pages/JuniorNews'
import JuniorCircular from'./Junior/Pages/JuniorCircular'
import JuniorEMagazine from'./Junior/Pages/JuniorEMagazine'
import JuniorEvents from'./Junior/Pages/JuniorEvents'
import JuniorSyllabus from'./Junior/Pages/JuniorSyllabus'
import JuniorVideo from'./Junior/Pages/JuniorVideo'
import KidsCorner from'./Junior/Pages/KidsCorner'
import JuniorPhotoGallery from'./Junior/Pages/JuniorPhotoGallery'
import JuniorPhotoCategory from'./Junior/Pages/JuniorPhotoCategory'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'lightbox.js-react/dist/index.css'
import SubGallery from "./Pages/SubGallery";
import LeapDay from'./Junior/Pages/LeapDay'
import ClassroomTeaching from'./Pages/ClassroomTeaching'

function App() {
  return (
    <>
    <BrowserRouter>
          <Routes>
           <Route  exact path="/" element={<Home/>}/>
           <Route  path="AboutSchool" element={<AboutSchool/>}/>
           <Route  path="SocietyPilar" element={<SocietyPilar/>}/>
           <Route  path="Vision" element={<Vision/>}/>
           <Route  path="Mission" element={<Mission/>}/>
           <Route  path="Goals" element={<Goals/>}/>
           <Route  path="OurPatron" element={<OurPatron/>}/>
           <Route  path="PrayerHymns" element={<PrayerHymns/>}/>
           <Route  path="Faculty" element={<Faculty/>}/>
           <Route  path="OurStrength" element={<OurStrength/>}/>
           <Route  path="Infrastructure" element={<Infrastructure/>}/>
           <Route  path="Curriculum" element={<Curriculum/>}/>
           <Route  path="SchoolTimings" element={<SchoolTimings/>}/>
           <Route  path="ParentCooperation" element={<ParentCooperation/>}/>
           <Route  path="AdmissionWithdrawal" element={<AdmissionWithdrawal/>}/>
           <Route  path="AdmissionRules" element={<AdmissionRules/>}/>
           <Route  path="ProcessAdmission" element={<ProcessAdmission/>}/>
           <Route  path="FeeRules" element={<FeeRules/>}/>
           <Route  path="WithdrawalDismissal" element={<WithdrawalDismissal/>}/>
           <Route  path="AssessmentRules" element={<AssessmentRules/>}/>
           <Route  path="LibraryRules" element={<LibraryRules/>}/>
           <Route  path="Attendance" element={<Attendance/>}/>
           <Route  path="Tc" element={<Tc/>}/>
           <Route  path="BookList" element={<BookList/>}/>
           <Route  path="PresidentMessage" element={<PresidentMessage/>}/>
           <Route  path="ManagerMessage" element={<ManagerMessage/>}/>
           <Route  path="PrincipalMessage" element={<PrincipalMessage/>}/>
           <Route  path="SeniorWingCoordinatorMessage" element={<SeniorWingCoordinatorMessage/>}/>
           <Route  path="SupervisorMessage" element={<SupervisorMessage/>}/>
           <Route  path="JuniorWingCoordinatorMessage" element={<JuniorWingCoordinatorMessage/>}/>
           <Route  path="Highlights" element={<Highlights/>}/>
           <Route  path="Teachers" element={<Teachers/>}/>
           <Route  path="Students" element={<Students/>}/>
           <Route  path="Magazine" element={<Magazine/>}/>
           <Route  path="PhotoGallery" element={<PhotoGallery/>}/>
           <Route  path="VideoGallery" element={<VideoGallery/>}/>
           <Route  path="Memories" element={<Memories/>}/>
           <Route  path="MediaGallery" element={<MediaGallery/>}/>
           <Route  path="VirtualTour" element={<VirtualTour/>}/>
           <Route  path="VoiceSms" element={<VoiceSms/>}/>
           <Route  path="NccArmy" element={<NccArmy/>}/>
           <Route  path="SocialOutreach" element={<SocialOutreach/>}/>
           <Route  path="NccNavy" element={<NccNavy/>}/>
           <Route  path="ManagementCommittee" element={<ManagementCommittee/>}/>
           <Route  path="SchoolParliament" element={<SchoolParliament/>}/>
           <Route  path="SxmunInformation" element={<SxmunInformation/>}/>
           <Route  path="Luminaire" element={<Luminaire/>}/>
           <Route  path="Career" element={<Career/>}/>
           <Route  path="ErpSoftware" element={<ErpSoftware/>}/>
           <Route  path="ParentPortal" element={<ParentPortal/>}/>
           <Route  path="Speech" element={<Speech/>}/>
           <Route  path="AlumniTestimonials" element={<AlumniTestimonials/>}/>
           <Route  path="ParentTestimonial" element={<ParentTestimonial/>}/>
           {/* <Route  path="PhotoCategories"  element={<PhotoCategories/>}/> */}
           <Route  path="SubGallery"  element={<SubGallery/>}/>
           <Route  path="NewsLetter" element={<NewsLetter/>}/>
           <Route  path="Circular" element={<Circular/>}/>
           <Route  path="Toppers" element={<Toppers/>}/>
           <Route  path="ToppersXII" element={<ToppersXII/>}/>
           <Route  path="Reminiscence" element={<Reminiscence/>}/>
           <Route  path="Alumni" element={<Alumni/>}/>
           <Route  path="Events" element={<Events/>}/>
           <Route  path="News" element={<News/>}/>
           <Route  path="Achievements" element={<Achievements/>}/>
           <Route  path="Committee" element={<Committee/>}/>
           <Route  path="SchoolUniform" element={<SchoolUniform/>}/>
           <Route  path="JuniorHome" element={<JuniorHome/>}/>
           <Route  path="JuniorNews" element={<JuniorNews/>}/>
           <Route  path="JuniorCircular" element={<JuniorCircular/>}/>
           <Route  path="JuniorEMagazine" element={<JuniorEMagazine/>}/>
           <Route  path="JuniorEvents" element={<JuniorEvents/>}/>
           <Route  path="JuniorSyllabus" element={<JuniorSyllabus/>}/>
           <Route  path="JuniorVideo" element={<JuniorVideo/>}/>
           <Route  path="KidsCorner" element={<KidsCorner/>}/>
           <Route  path="JuniorPhotoGallery" element={<JuniorPhotoGallery/>}/>
           <Route  path="JuniorPhotoCategory" element={<JuniorPhotoCategory/>}/>
           <Route  path="LeapDay" element={<LeapDay/>}/>
            <Route  path="ClassroomTeaching" element={<ClassroomTeaching/>}/>
          </Routes>
     </BrowserRouter>
    </>
  );
}

export default App;
