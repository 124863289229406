import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import ProspectSidebar from'./ProspectSidebar'
import { Link } from 'react-router-dom'
const AdmissionRules = () => {
  return (
    <>
<Header/>
    <div className="inner_slider">
    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
    <div className="row bread_cumb">
    <h2>Admission Rules</h2>
    <div className="theme-heading">
    <span className="colorW marB10"><i className="bi bi-building"></i></span>
    </div>
    <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span>  Prospectus <i className="bi bi-arrow-right"></i> Admission Rules</span></h5>
    </div>
    
    </div>
    <div className="innerpage">
<div className="row inner_cont">
   <div className="col-lg-9 col-md-8 col-sm-12 col-12">
   <ul>
   <li>The management reserves to itself, the right of admission.</li>
   <li>In considering a candidate for admission, the admission Pro-forma should be duly filled and signed by one of the parents, must be submitted to school. If neither of the parent is alive or available, the lawful guardian of the candidate shall sign the form.</li>
   <li>A child who has attained the age of 3 years by the 31st of December of the respective academic year is eligible for admission to the L.K.G. Class. Admission to all higher classes will be determined by this rule.</li>
   <li>A child who has previously studied in any other recognized school will have to submit a transfer certificate and a report card from the school last attended. Transfer certificate from a school affiliated to a board than the CBSE New Delhi, must be countersigned by the education officer of the concerned District.</li>
   <li>Municipal Birth Certificate will have to be submitted for admission of a child who has not attended any recognized school previously. Special attention must be paid for entering correctly, the name and date of birth of the child. Subsequent change may not be permitted.</li>
   <li>Admission to any class above L.K.G. is finalized strictly on merit, after screening/ interview/test, as per the decision of the management and payment of the prescribed fee made thereafter, which once paid will not be refunded.</li>
   <li>The pupil will be examined on the Syllabus of the class, immediately below that to which he/she seeks admission and may have to answer the entrance test if the Principal so decides. The latter's decision regarding the entrance test shall be final.</li>
   <li>Within six working days after the selection of the candidate, all fee and other charges must be deposited in the Bank, and its counterfoil should be presented at the School.</li>
   <li>Even if admission is granted in any month after April, fee and other charges of the entire term have to be paid.</li>
   <li>The caution money is however refundable on withdrawal of a child after giving due notice or on completion of studies in the school, after producing the original receipt within a year, failing of which the caution money will be considered as a donation to the school.</li>
   <li>Before admission and shifting residence, parents should satisfy themselves, the adequate transport arrangement existing for their wards, as school doesn't provide any transport.</li>
   <li>Section allotment is entirely at the discretion of the school management.</li>
   </ul>
   <div className="clr"></div>
   <p className="large_font"><strong>Academically, the school is split into following sections :</strong> </p>
   <ul>
   <li>K.G. Section</li>
   <li>Primary Section (From I-V)</li>
   <li>Middle Section (From VI-VIII)</li>
   <li>High School Section (From IX-X)</li>
   <li>Senior Secondary (From XI-XII)</li></ul>
   <div className="clr"></div>
   <div className="clr"></div>
   </div>

<ProspectSidebar/>

</div>
</div>
  <Footer/>
  </>
  )
}

export default AdmissionRules
