import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import ProspectSidebar from'./ProspectSidebar'
import { Link } from 'react-router-dom'
const SchoolTimings = () => {
  return (
    <>
<Header/>
    <div className="inner_slider">
    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
    <div className="row bread_cumb">
    <h2>School Timings</h2>
    <div className="theme-heading">
    <span className="colorW marB10"><i className="bi bi-building"></i></span>
    </div>
    <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span>  Prospectus <i className="bi bi-arrow-right"></i> School Timings</span></h5>
    </div>
    
    </div>
    <div className="innerpage">
<div className="row inner_cont">
   <div className="col-lg-9 col-md-8 col-sm-12 col-12">
   <div className="innertable">
   <table width="100%">
   <tbody>
   <tr>
   <td  rowspan="2"><b>Pre-Primary</b></td>
   <td >Summer</td>
   <td >07:20 AM &nbsp; to &nbsp; 12:30 PM</td>
   </tr>
   <tr>
   <td >Winter</td>
   <td >8:20 AM &nbsp; to  &nbsp; 1:10 PM</td>
   </tr>
   <tr>
   <td  rowspan="2"><b>Std. I to X </b></td>
   <td >Summer</td>
   <td >07:20 AM &nbsp; to &nbsp; 01:30 PM</td>
   </tr>
   <tr>
   <td >Winter</td>
   <td > 8:20 AM &nbsp; to &nbsp; 2:10 PM</td>
   </tr>
   <tr>
   <td  rowspan="2"><b> Saturday: </b></td>
   <td >Summer</td>
   <td >07:20 AM &nbsp; to &nbsp; 11:30 AM</td>
   </tr>
   <tr>
   <td >Winter</td>
   <td >8:20 AM &nbsp; to &nbsp; 12:10 PM</td>
   </tr>
   <tr>
   <td  rowspan="2"><b> XI to XII </b></td>
   <td >Summer</td>
   <td >07:20 AM &nbsp; to &nbsp; 12:30 PM</td>
   </tr>
   <tr>
   <td >Winter</td>
   <td >08:00 AM &nbsp; to &nbsp; 12:25 PM</td>
   </tr>
   <tr>
   <td ><b> Saturday</b></td>
   <td  colspan="2"> Pre-Primary Holiday </td>
   </tr>
   </tbody>
   </table>
   </div>
   <div className="clr10"></div>
   <div className="innertable">
   <table width="100%">
                   <tbody>
                   <tr>
   <th colspan="2"><b> TIME TO MEET </b></th>
   </tr>
   
   <tr>
   <td> Principal </td>
   <td> Between 9:00 AM &nbsp; to &nbsp;  11:00 AM </td>
   </tr>
   
   <tr>
   <td> Teacher </td>
   <td> With prior appointment </td>
   </tr>
   
   <tr>
   <td> School Office </td>
   <td> 9:00 AM to 1:00 PM <br/>
   No school business will be transacted on holidays. 
    </td>
   </tr>
   
   <tr>
   <td> Junior Wing </td>
   <td> 9:00 AM to 11:00 AM <br/>
   12:30 PM to 2:30 PM  
    </td>
   </tr>
   
                   
                   </tbody></table>
   </div>
   <div className="clr10"></div>
   <p className="large_font"><strong>WHEN AND WHERE CAN PARENTS MEET THE TEACHERS</strong></p>
   <ul>
   <li>You may meet the teacher on the Parents Teacher meeting days (place : Assigned Rooms)</li>
   <li>You may meet the teacher by asking through the student's Diary for an appointment with the teacher. (Place : Reception Lobby Only).</li>
   </ul>
   
   <div className="clr"></div>
       <div className="row">
   <div className="col-lg-6 col-md-6 col-sm-6 col-6"><center><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/society.jpg" alt="St. Xaviers Bathinda" className="sec_imgaes_new img-fluid" /></center></div>
   <div className="col-lg-6 col-md-6 col-sm-6 col-6"><center><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/society1.jpg"  alt="St. Xaviers Bathinda" className="sec_imgaes_new img-fluid" /></center></div>
           </div>
   <div className="clr"></div>
   </div>
<ProspectSidebar/>

</div>
</div>
  <Footer/>
  </>
  )
}

export default SchoolTimings
