import React from 'react'

const NonTeachingStaff = () => {
   return (
    <>
  <tr><td>1</td><td>Mrs. Bhupinder Kaur</td><td>Librarian</td><td>B.A, B.Ed, M.L.I S</td></tr><tr><td>2</td><td>Mr. Clyde Romaldo Torres</td><td>Accountant</td><td>B.com</td></tr><tr><td>3</td><td>Ms. Aldrina Pereira</td><td>Accountant</td><td>B.com</td></tr><tr><td>4</td><td>Mrs. Simmi John</td><td>Clerk</td><td>B.sc</td></tr><tr><td>5</td><td>Ms. Ronisha Fernandes</td><td>Clerk</td><td>B.A</td></tr><tr><td>6</td><td>Ms. Bambina Anita Fernandes</td><td>Clerk</td><td>B.B.A</td></tr><tr><td>7</td><td>Ms. Swizerl Fernandes</td><td>Clerk</td><td>B.Com</td></tr><tr><td>8</td><td>Ms. Elita Oliveira</td><td>Clerk</td><td>Diploma in Computer Application</td></tr><tr><td>9</td><td>Ms. Rosy Gurung</td><td>Clerk</td><td>B.A, M.A, LLB</td></tr><tr><td>10</td><td>Mr. Surinder Singh</td><td>Smart Class Coordinator</td><td>B.sc, IT</td></tr><tr><td>11</td><td>Mr. Shahid Maqsood</td><td>Smart Class Coordinator</td><td>B.A, PGDCA</td></tr><tr><td>12</td><td>Mr. Armeet Singh</td><td>Electrician</td><td>Television Mechanic Course</td></tr><tr><td>13</td><td>Mr. Gursewak Singh</td><td>Electrician</td><td>B.Tech</td></tr><tr><td>14</td><td>Mr. Parbhakar Rao</td><td>Graphic Designer</td><td>Diploma in Graphic Designing</td></tr> <tr><td>15</td><td> Ms. Sabreena Manzoor </td>  	<td> Nurse	</td>   <td>	B.sc Nursing </td></tr>
	<tr><td>16</td><td> Ms. Jaspreet Kaur </td> <td> Nurse	</td> <td>	GNM Diploma in Nursing	 </td> </tr>

  </>
  )
}

export default NonTeachingStaff
