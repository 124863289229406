import React, { useEffect, useState } from 'react';
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import { Link } from 'react-router-dom';
import { getVideo } from '../Service/Api';

const VideoGallery = () => {
    const [selectedOption, setSelectedOption] = useState('option2024');
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState();
    const [year, setYear] = useState([]);
   
    useEffect(() => {
        const fetchData = async () => {
            const galleryData = await getVideo();
            galleryData.sort((a, b) => new Date(b.date) - new Date(a.date)); // Sort by date in descending order
            setData(galleryData);
            const uniqueYears = Array.from(
                new Set(galleryData.map((item) => new Date(item.date).getFullYear()))
            );
            uniqueYears.sort((a, b) => b - a);
            setYear(uniqueYears);
            console.log(galleryData);
            setFilteredData(galleryData); // Initialize filteredData with all data
        };
        fetchData();
    }, []);
   
    useEffect(() => {
        console.log("events_data", data);
    }, [data]);
   
    const emptyArray = [
        { title: "Video Title", date: "DD/MM/YYYY" },   
        { title: "Video Title", date: "DD/MM/YYYY" },   
        { title: "Video Title", date: "DD/MM/YYYY" },   
    ];
   
    useEffect(() => {
        // Filter data based on selected year
        if (selectedYear && selectedYear !== "All") {
            const filteredData = data.filter((item) => {
                const itemYear = new Date(item.date).getFullYear();
                return itemYear === parseInt(selectedYear);
            });
            setFilteredData(filteredData);
        } else {
            // If selected year is "All" or not selected, set filterData to all data
            setFilteredData(data);
        }
    }, [selectedYear, data]); // Added 'data' as a dependency

    const monthsOfYear = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
    ];

    console.log(data)

    console.log(data.filter((item)=>{return item.videoCategory==='ClassVideo'}))
   
    return (
        <>
            <Header/>
            <div className="inner_slider">
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
                <div className="row bread_cumb">
                    <h2>Video Gallery</h2>
                    <div className="theme-heading">
                        <span className="colorW marB10"><i className="bi bi-building"></i></span>
                    </div>
                    <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> Bulletin <i className="bi bi-arrow-right"></i> Gallery  <i className="bi bi-arrow-right"></i>  Video Gallery</span></h5>
                </div>
            </div>
            <div className="innerpage">
                <div className="row inner_cont">
                    <div className="col-lg-12">
                        <div className="chooseyear">
                            {/* Year Selection Dropdown */}
                            <select
                                value={selectedYear}
                                onChange={(e) => setSelectedYear(e.target.value)}
                                className="yearSelection"
                            >
                                <option value={"All"}>All</option>
                                {year.map((yr) => (
                                    <option key={yr} value={yr}>{yr}</option>
                                ))}
                            </select>
                        </div>
                        <div className="clr"></div>
                        {selectedOption === 'option2024' &&  
                            <div className="row">
                                {filterData && filterData.length > 0 ? (
                                    filterData?.filter((item) => (item.school === "Senior" || item.school === "Both") && item.videoCategory !== "ClassVideo") .map((item, index) => (
                                        <div key={index} className="col-xl-3 col-lg-3 col-md-6 col-sm-12"> 
                                            <div className="video-gallery"> 
                                                <Link to={item.videoUrl} target="_blank">
                                                    <div> 
                                                        <img src={item.attachments.length ? `https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}` : 'placeholder-image-url'} className="img-fluid" alt="St. Xaviers Bathinda" />
                                                        <h3>{item.title}</h3> 
                                                        <p>{item.date}</p> 
                                                    </div> 
                                                </Link>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    emptyArray.map((data, index) => (
                                        <div key={index} className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                            <div className="video-gallery">
                                                <Link to="#" target="_blank">
                                                    <i className="bi bi-play-circle-fill"></i>
                                                    <div>
                                                        <h3>{data.title}</h3>
                                                        <p>{data.date}</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default VideoGallery;
