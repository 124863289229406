import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getEvents } from '../../Service/Api';
const Events = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const eventData = await getEvents();
        setData(eventData);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log("events_data", data);
  }, [data]);

  const emptyArray = [
    { title: "Event Title", date: "DD/MM/YYYY", time: "00:00", venue: "School", images: "https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/events.jpg", description: "Stay tuned for latest updates" },
    { title: "Event Title", date: "DD/MM/YYYY", time: "00:00", venue: "School", images: "https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/events.jpg", description: "Stay tuned for latest updates" },
    { title: "Event Title", date: "DD/MM/YYYY", time: "00:00", venue: "School", images: "https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/events.jpg", description: "Stay tuned for latest updates" }
  ];

  return (
    <>
    <div className="col-12 col-sm-12 col-md-4 col-lg-4">
    <h1 className="sub_title">Upcoming <span>Events</span></h1>
    
    {data?.length > 0 ? data?.filter((item) => item.school === "Both" || item.school === "Junior").slice(0, 3).map((item, index) => (
    
    <article key={index}>
    <Link to="#" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/JuniorImages/attachment.png"  className="atta_icon" alt="St. Xaviers Bathinda" /></Link><div className="clr"></div>
    <h3>{item.title}</h3>
    <p>{item.description}</p>
    <div className="clr"></div>
    </article>


)) : emptyArray.map((item, index) => (
  <article key={index}>
  <Link to="#" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/JuniorImages/attachment.png"  className="atta_icon" alt="St. Xaviers Bathinda" /></Link><div className="clr"></div>
  <h3>{data.title}</h3>
  <p>{data.description}</p>
  <div className="clr"></div>
  </article>
))}
    <Link className="dedcription-btn" to="/JuniorEvents" ><span className="name-descripeion">View All</span><div className="btn-icon book"><i className="fas fa-book-reader"></i></div></Link>
    </div>
    </>
  )
}

export default Events
