import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import { NavLink , Link } from 'react-router-dom'
const ParentTestimonial = () => {
  return (
    <>
<Header/>
     <div className="inner_slider">
     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
     <div className="row bread_cumb">
     <h2>Parent Testimonial</h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> Parent Testimonial</span></h5>
     </div>
     
     </div>
     <div className="innerpage">
<div className="row inner_cont">
<div className="col-lg-9 col-md-8 col-sm-12 col-12 testimonial_sec">
<div className="row">
<article>
<p className="quotes">We thank all the teachers for putting such immense effort into the online session. Hardik has been able to adjust well. Thanks to his teachers for having been patient with my child. The faculty has been making sure that every child is comfortable with the technology and receives complete attention. it's indeed a commendable job by the school. I personally feel very satisfied and happy with the performance and responsibility of the school.</p>
<p><strong>Thanks and Regards</strong></p>
<p>Parent of Hardik Garg - 8th B </p>
<div className="clearfix"></div>
</article>
  <article>
<p className="quotes">Being a parent, I would always admire if the school will properly take care of my ward. This is Something I found In Saint Xavier's.<br />Here, the teachers are highly responsible and dedicated to their work. They put great efforts to make our children hard working and shine brighter. We, as parents want to receive the best possible results which we are getting from the school.</p>
<p><strong>Thanks and Regards</strong></p>
<p>Parent of Abhinav Garg – 6th D</p>
<div className="clearfix"></div>
</article>
  <article>
<p  className="quotes">Every parent while choosing a school for his child has to consider various aspects and has certain expectations. When I chose St. Xavier's School, Bathinda, I saw the academic excellence which the school had put forward since years of its establishment along with the overall holistic growth and character development with which they nurture kids. I'm really happy and proud about the acquisition of skills in my child.</p>
<p><strong>Thanks and Regards</strong></p>
<p>Parent of Rupam – 6th D</p>
<div className="clearfix"></div>
</article>
  <article>
<p className="quotes">I commend the St. Xavier's School on the excellent communication and way with which this whole unsettling time has been handled and our children have been looked after. Such an excellent job is done by all at School and much appreciated by myself as well as the other parents. What a team of talented, caring, strong, and intelligent teachers this school has. We are always pleased by the fact that my child is in exceptional hands. </p>
<p><strong>Thanks and Regards</strong></p>
<p>Parent of Harseerat Gill – 4th B</p>
<div className="clearfix"></div>
</article>
  <article>
<p className="quotes">I love my son to study in one of the best schools in the city. The staff of the school is very kind and nice-hearted. The way of teaching by the teachers is very impressive and easy for our children to remember the concepts. The school has a massive playground along with towering and tall buildings. I feel happy that my child is in safe hands. </p>
<p><strong>Thanks and Regards</strong></p>
<p>Parent of Hardeep Singh Sidhu – 7th C </p>
<div className="clearfix"></div>
</article>
  <article>
<p className="quotes">As a parent I hope when I leave my daughter at school, she feels safe, secure, happy and ultimately learning, So, I choose Saint Xavier for my kids. the teachers of this school are extremely dedicated and well qualified. they put strong efforts to involve parents, this relationship between parents and teachers allows us to enhance the opportunity to understand the full educational experience.</p>
<p><strong>Thanks and Regards</strong></p>
<p>Parent of Nazam Preet – 7th D </p>
<div className="clearfix"></div>
</article>
  <article>
<p className="quotes">St. Xavier as we all know is the pioneer institution in this region. The name itself satisfies us. The atmosphere and the care given to my ward is remarkable. The entire faculty provides an environment of all-round development. even now when my ward is in class 10th I feel the same friendly and comfortable atmosphere that I felt 10 years ago. I would like to thank all the dedicated and hard-working teachers for their commendable role .in short it's a dream come true being a part of Xavier's family.</p>
<p><strong>Thanks and Regards</strong></p>
<p>Parent of Moh. Rehan Khan - 10th A </p>
<div className="clearfix"></div>
</article>
</div>
<div className="clr"></div>
</div>
<div className="col-lg-3 col-md-4 col-sm-12 col-12">
<div className="inner_menu">
<h3>Menu Choice</h3>
<ul id="sidebar">
<li><NavLink id="A2" to="/AlumniTestimonials">Alumni Testimonials</NavLink> </li>
<li><NavLink id="A3" to="/ParentTestimonial">Parent Testimonials</NavLink> </li>
</ul>
<div className="clr"></div>
</div>
<div className="important_menu">
<h3>Important Menu</h3>
<ul>
<li><NavLink to="/VirtualTour"><i className="fas fa-camera" aria-hidden="true"></i> 360<sup>o</sup></NavLink></li>
<li><NavLink to="/ParentPortal"><i className="fa fa-newspaper" aria-hidden="true"></i> Portal Help?</NavLink></li>
<li><NavLink to="/ErpSoftware"><i className="fas fa-desktop" aria-hidden="true"></i> ERP Software</NavLink></li>
<li><NavLink to="/Junior/Default"><i className="fas fa-child" aria-hidden="true"></i> Kid's Corner</NavLink></li>
<li><NavLink to="/Achievements"><i className="fa fa-trophy" aria-hidden="true"></i> Achievements</NavLink></li>
<li><NavLink to="/Speech"><i className="fa fa-microphone" aria-hidden="true"></i> Speech</NavLink></li>
</ul>
<div className="clr"></div>
</div>
</div>
</div>
</div>
   <Footer/>
  </>
  )
}

export default ParentTestimonial
