import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import { SlideshowLightbox } from 'lightbox.js-react'
import { getNews } from '../../Service/Api'

const JuniorNews = () => {
  
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState("All");
    const [selectedMonth, setSelectedMonth] = useState("All");
    const [yearRanges, setYearRanges] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const monthsOfYear = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const handleMonth = (month) => {
        return monthsOfYear[month - 1];
    };

    const emptyArray = [
        { title: "News Title", description: "Stay tuned for latest updates", attachments: [""] },
        { title: "News Title", description: "Stay tuned for latest updates", attachments: [""] },
    ];

    useEffect(() => {
        const fetchData = async () => {
            const galleryData = await getNews();
            setData(galleryData);

            const uniqueYears = Array.from(
                new Set(galleryData.flatMap((item) => {
                    if (item.fromDate) {
                        const year = new Date(item.fromDate).getFullYear();
                        return [year];
                    } else {
                        return [];
                    }
                }))
            ).sort((a, b) => b - a);

            setYearRanges(uniqueYears);
            setSelectedYear("All");
            setSelectedMonth("All");
        };
        fetchData();
    }, []);

    useEffect(() => {
        let filteredData = data;

        if (selectedYear !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.fromDate && item.fromDate.trim() !== "") {
                    const year = new Date(item.fromDate).getFullYear();
                    const month = new Date(item.fromDate).getMonth() + 1;
                    return parseInt(selectedYear) === year && (selectedMonth === "All" || parseInt(selectedMonth) === month);
                } else {
                    return false;
                }
            });
        }

        if (selectedMonth !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.fromDate && item.fromDate.trim() !== "") {
                    const month = new Date(item.fromDate).getMonth() + 1;
                    return parseInt(selectedMonth) === month;
                } else {
                    return false;
                }
            });
        }

        if (searchQuery) {
            filteredData = filteredData.filter((item) =>
                item.title.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        // Sort the data by date in descending order (latest first)
        filteredData.sort((a, b) => new Date(b.fromDate) - new Date(a.fromDate));

        setFilteredData(filteredData);
    }, [selectedYear, selectedMonth, searchQuery, data]);

    return (
   <>
   <div className="kids">
   <div className="container juniorcontainer">
   <div className="row">
   <div className="col-lg-6 col-md-6 col-12">
   <Link to="/JuniorHome"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/JuniorImages/logo.png" className="img-fluid logo" alt="St. Xaviers Bathinda" /></Link>

   </div>
   <div className="col-lg-6 col-md-6 col-12">
    <Link to="/JuniorHome"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/JuniorImages/kids.png" className="img-fluid kids-logo" alt="St. Xaviers Bathinda" /></Link>
   <div className="kintergarten_slider_sec_btm_bird"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/JuniorImages/bird.gif" className="img-fluid"/></div>
   </div>
   </div>
   <div className="kids-back-sec">
   <h2>Latest News</h2>
   
   <div className="news-inner">
             
   <div className="row tabs-dynamic">
                        <div className="col-md-3">
                            <div className="count-val">
                                <p>Total Count: {filterData.length}</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="month-selection">
                                <select id="yearSelection" onChange={(e) => setSelectedYear(e.target.value)} value={selectedYear}>
                                    <option value="All">All Years</option>
                                    {yearRanges.map((yr, index) => (
                                        <option key={index} value={yr}>{yr}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="month-selection">
                                <select className="monthSelect" onChange={(e) => setSelectedMonth(e.target.value)} value={selectedMonth}>
                                    <option value="All">All Months</option>
                                    {monthsOfYear.map((month, index) => (
                                        <option key={index} value={index + 1}>{month}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="searchBox">
                                <input
                                    type="text"
                                    id="myInput"
                                    name="name"
                                    autoComplete="off"
                                    placeholder="Search Here.."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row years year2024 showYear">
                        {filterData && filterData.length > 0 ? filterData?.filter((item) => item.school === "Junior" || item.school === "Both" && item.category === "News").map((item, index) => {
                            const date = new Date(item.fromDate);
                            const day = date.getDate();
                            const month = handleMonth(date.getMonth() + 1);
                            const year = date.getFullYear();

                            return (
                                <div key={index} className="col-xl-6 col-lg-6 col-md-12 col-sm-12 eventCount " data-month="1">
                                    <div className="newsbox">
                                    <div className="newsboximg">
  {item.image && item.image[0] ? (
    <a data-magnify="gallery" data-src="" data-group="a" href={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.image[0]}`}>
      <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.image[0]}`} className="img-fluid" alt="" />
    </a>
  ) : (
    <a data-magnify="gallery" data-src="" data-group="a" href="/Images/news.jpg">
      <img src="/Images/news.jpg" className="img-fluid" alt="" />
    </a>
  )}
</div>

                                        <div className="newsboxdesc">
                                            <div className="newspgh">
                                                <h5>{item.title}</h5>
                                                <p>{item.description}</p>
                                            </div>
                                            <div className="newsdate">
                                                <span>{day} {month} {year}</span>
                                                {item.attachments && item.attachments.length > 0 && (
  <Link
    to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`}
    target="_blank"
  >
    <i className="bi bi-paperclip"></i>
  </Link>
)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        }) :
                            emptyArray.map((item, index) => (
                                <div key={index} className="col-xl-6 col-lg-6 col-md-12 col-sm-12 eventCount " data-month="1">
                                    <div className="newsbox">
                                        <div className="newsboximg">
                                            <a data-magnify="gallery" data-src="" data-group="a" href="Images/news.jpg"><img src="Images/news.jpg" className="img-fluid" alt="" /></a>
                                        </div>
                                        <div className="newsboxdesc">
                                            <div className="newspgh">
                                                <h5>{item.title}</h5>
                                                <p>{item.description}</p>
                                            </div>
                                            <div className="newsdate">
                                                <span>DD MM YYYY</span>
                                                <Link to="#" target="_blank"><i className="bi bi-paperclip"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
             </div>
         
   
   </div>
   </div>
   </div>
   <Footer/>
   </>
  )
}

export default JuniorNews
