import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import { NavLink , Link } from 'react-router-dom'
const AlumniTestimonials = () => {
  return (
    <>
<Header/>
     <div className="inner_slider">
     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
     <div className="row bread_cumb">
     <h2>Alumni Testimonials</h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span>  Alumni Testimonials</span></h5>
     </div>
     
     </div>
     <div className="innerpage">
<div className="row inner_cont">
<div className="col-lg-9 col-md-8 col-sm-12 col-12 testimonial_sec">
<div className="row">
 <article>
 <h3>Jaspreet kaur </h3>
 <p className="quotes">My name is Jaspreet Kaur. I had done my schooling till post metric in the year 2013. I joined Xavier's family in 8th class. Being a new member of the school, I found the staff supportive and friendly. I performed a bit better every next day. St. Xavier covers the whole learning process and helps in developing the personality as a whole. the curriculum it provides is contemporary to the present time. Besides the studies, it also aims at enhancing communication skills, so that a student can foster in the whole environment. I have earned a lot being a student of St. Xavier's Bathinda. It taught me the principles that form the overall personality and has made me able to process thoughts more effectively. I feel fortunate for having been a part of it. Thank you, Fr. Bernard, Fr. Remo and all the faculty for providing the best schooling.</p>
 <div className="clearfix"></div>
 </article>
  <article>
 <h3>Himanshu Goyal </h3>
 <p className="quotes">This is Himanshu Goyal, I completed high school at Xavier's in 2013. I am proud that I was a part of that wonderful family. I didn't do great in my academics in school because I didn't have any ambition at that point of time. Well, the teachers were great they nurtured us to become better citizens of the country and competitive enough to crack future exams. What Xavier's did well at that point of the time was they gave us early access to the knowledge behind computers. The world as we see these days is becoming more digital these days and digitalization is the future. I believe I can understand the operating systems so well without having a degree in computer science is because of my schooling. The Xavier's made me understand the importance of discipline, health, leadership and technology by imparting me chances to showcase those time to time.
</p>
 <div className="clearfix"></div>
 </article>
  <article>
 <h3>Chrales Bansal </h3>
 <p className="quotes">Myself Charles Bansal,<br />
Recalling my experience in the Xavier's it was a memorable one especially the teachers who bonded with us a lot and made teaching a better experience for me and surely for the others also. Presently, I just completed my MBBS and a major contribution of this goes to the school for laying the foundation stone which made me capable of thinking of such a big dream and achieving it with great honours.</p>
 <div className="clearfix"></div>
 </article>
  <article>
 <h3>Lovish Garg </h3>
 <p className="quotes">As an alumnus of St. Xavier's would like to say that I have spent 12 years of my childhood at St. Xavier's i.e. from (L.K.G. TO 10Th). And I feel grateful and blessed that I am a part of St Xavier's family. This institution has taught me many things like discipline, punctuality, right utilization of time, differentiating between what is right and what is wrong, respecting and treating each person that you deal with and many other things necessary to be a good human. Now when I look by I remember each moment that I have spent in Xavier's a smile comes on my face and if I ever get a chance to relive these moments with my Xavier's family I would never let it go.</p>
 <p>Proud to be a St. Xavier's Alumni</p>
 <p><strong>Lovish Garg</strong></p>
 <div className="clearfix"></div>
 </article>
  <article>
 <h3>Atish Gupta</h3>
<p className="quotes">I am a proud Xavierite. I took admission here as I had heard "It's The Best!".<br />At first, I was a really shy kid. Not interacting much with others and always being alone, when the teachers helped me in opening up.<br />The faculty is amazing! Keeping the kids up to date with the current technologies and teaching them necessities for their future life,<br />morals and respect for others while keeping the curriculum engaging and fun.<br />Similarly amazing are their facilities. Smart classes are installed for each class and are taught via interactive classes so the kids learn and never get bored.<br />Being in Xavier's taught me from friendship to leadership, from discipline to citizenship and nurtured me from a boring shy kid to a funny cheerful adult.<br />These were the memorable 12 years of my life that were so precious that I still visit the school with my friends.</p>
 <div className="clearfix"></div>
 </article>
  <article>
 <h3>Pushkar Bansal</h3>
 <p className="quotes">St. Xavier's acted as a primary foundation in my life, making me aware of the Basics of Alphabet and Numbers from Geography to Music Classes to Hindi Classes to Punjabi to making some awesome friends to enjoying delicious and yummy
food at Canteen to playing Cricket and Football games. With all this, the biggest tool which I even practise today, and got to learn from my school days are, 'The Tools of Gratitude and Calmness'. Performing my duties as Cabinet Member to Stage Anchor to Performer boosted my personality to the next level and contributed<br />to what I am today. Digitalization is something in which we all are living, but I come from a generation where it all began, and St. Xavier's made sure that I<br />wasn't behind the beginning of this era, and made aware of computers and technology via smart e-class mode. My teachers, a special thanks to all my lovable teachers, who not only made me a better human being but also shared their life lessons which I<br />use in my day-to-day activities and overcome difficulties and challenges.</p>
 <p>THANK YOU ST. XAVIER'S BATHINDA FOR EVERYTHING</p>
 <div className="clearfix"></div>
 </article>
  <article>
 <h3>Lakshey Jindal</h3>
 <p className="quotes">I heard someone saying "school is the golden period of life"<br />I completely agree with this. My years spent in St. Xavier's high school were the golden years. My personality was moulded in the precious hands of my teachers). This prestigious institution helped me achieve my goals in life. The dedicated faculty, staff has worked hard and played an important role in the name that the institution has made for itself today.<br />I feel proud to say that I was once a part of this great institution and many students passed out hold good positions in society today. I am confident that this great institution will continue to groom students in taking up positions as leaders in future of our country.</p>
 <div className="clearfix"></div>
 </article> 
</div>
<div className="clr"></div>
</div>
<div className="col-lg-3 col-md-4 col-sm-12 col-12">
<div className="inner_menu">
<h3>Menu Choice</h3>
<ul id="sidebar">
<li><NavLink id="A2" to="/AlumniTestimonials">Alumni Testimonials</NavLink> </li>
<li><NavLink id="A3" to="/ParentTestimonial">Parent Testimonials</NavLink> </li>
</ul>
<div className="clr"></div>
</div>
<div className="important_menu">
<h3>Important Menu</h3>
<ul>
<li><NavLink to="/VirtualTour"><i className="fas fa-camera" aria-hidden="true"></i> 360<sup>o</sup></NavLink></li>
<li><NavLink to="/ParentPortal"><i className="fa fa-newspaper" aria-hidden="true"></i> Portal Help?</NavLink></li>
<li><NavLink to="/ErpSoftware"><i className="fas fa-desktop" aria-hidden="true"></i> ERP Software</NavLink></li>
<li><NavLink to="/Junior/Default"><i className="fas fa-child" aria-hidden="true"></i> Kid's Corner</NavLink></li>
<li><NavLink to="/Achievements"><i className="fa fa-trophy" aria-hidden="true"></i> Achievements</NavLink></li>
<li><NavLink to="/Speech"><i className="fa fa-microphone" aria-hidden="true"></i> Speech</NavLink></li>
</ul>
<div className="clr"></div>
</div>
</div>
</div>
</div>
   <Footer/>
  </>
  )
}

export default AlumniTestimonials
