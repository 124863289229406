import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getVideo } from '../Service/Api';

const HomeVideoGallery = () => {
  const [data, setData] = useState([]);
  const [filterData, setFilteredData] = useState([]);
  const [selectedYear, setSelectedYear] = useState('All');
  const [year, setYear] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const galleryData = await getVideo();
      setData(galleryData);
      setFilteredData(galleryData); // Initialize filteredData with all data
      const uniqueYears = Array.from(
        new Set(galleryData.map((item) => new Date(item.date).getFullYear()))
      );
      uniqueYears.sort((a, b) => b - a);
      setYear(uniqueYears);
    };
    fetchData();
  }, []);

  useEffect(() => {
    // Filter data based on selected year
    if (selectedYear && selectedYear !== "All") {
      const filteredData = data.filter((item) => {
        const itemYear = new Date(item.date).getFullYear();
        return itemYear === parseInt(selectedYear);
      });
      setFilteredData(filteredData);
    } else {
      // If selected year is "All" or not selected, set filterData to all data
      setFilteredData(data);
    }
  }, [selectedYear, data]);

  const emptyArray = [
    { title: "Video Title", date: "DD/MM/YYYY" },
  ];

  const singleItem = filterData.length > 0 ? filterData[0] : null;

  return (
    <>
      {singleItem ? (
        <div className="col-xl-3 col-lg-6 col-sm-6 col-12">
          <article className="video-arti">
            <Link target='_blank' to={singleItem.videoUrl}>
              <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/video-img.jpg" className="img-fluid" alt="St. Xaviers Bathinda" />
            </Link>
            <h3>{singleItem.title}</h3>
            <div className="link">
              <Link to="/VideoGallery"><i className="bi bi-arrow-90deg-right"></i></Link>
            </div>
          </article>
        </div>
      ) : (
        emptyArray.map((data, index) => (
          <div className="col-xl-3 col-lg-6 col-sm-6 col-12" key={index}>
            <article className="video-arti">
              <Link to="/VideoGallery">
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/video-img.jpg" className="img-fluid" alt="St. Xaviers Bathinda" />
              </Link>
              <h3>{data.title}</h3>
              <div className="link">
                <Link to="/VideoGallery"><i className="bi bi-arrow-90deg-right"></i></Link>
              </div>
            </article>
          </div>
        ))
      )}
    </>
  );
}

export default HomeVideoGallery;
