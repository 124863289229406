import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import ProspectSidebar from'./ProspectSidebar'
import { Link } from 'react-router-dom'
const ParentCooperation = () => {
  return (
    <>
<Header/>
    <div className="inner_slider">
    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
    <div className="row bread_cumb">
    <h2>Parent's Co-operation</h2>
    <div className="theme-heading">
    <span className="colorW marB10"><i className="bi bi-building"></i></span>
    </div>
    <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span>  Prospectus <i className="bi bi-arrow-right"></i> Parent's Co-operation</span></h5>
    </div>
    
    </div>
    <div className="innerpage">
<div className="row inner_cont">
   <div className="col-lg-9 col-md-8 col-sm-12 col-12">

   <p>Parental involvement has always been an integral part of the school- teacher- student academic endeavor. Many parents, whose children are currently enrolled in any school, like to be equally involved in everything. 
    </p>
    <p>A positive two-way communication plays the key role in developing the cooperation. So as to create opportunities for communication schools continue to organize events where parents are cordially invited. Parents play an active role in making the system of the school run smoothly by following the norms and the protocols and, it also leaves a positive impact on the child’s academic achievements. 
     </p>
   <p>These days when corona is not coming to a standstill, the internet has come forward to rescue, due to which online classes have become an everyday part of students, teachers and parents equally. 
    </p>
   <p>Keeping a pace with the online classes has become a challenge not only for the teachers but for the parents as well nevertheless, Parents are doing it nicely. They are smartly juggling between their professional duties and their daily errands to make things possible for their child’s education. Even if it is the supervision of the online timetable of classes or the availability and the smooth functioning of the internet, parents’ cooperation is everywhere. 
    </p>
   <p>Thus, when it comes to the student’s development it needs continuous care and cooperation from both parents and schools.   </p>
   
   </div>

<ProspectSidebar/>

</div>
</div>
  <Footer/>
  </>
  )
}

export default ParentCooperation
