import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import ProspectSidebar from'./ProspectSidebar'
import { Link } from 'react-router-dom'
const Curriculum = () => {
  return (
    <>
<Header/>
     <div className="inner_slider">
     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
     <div className="row bread_cumb">
     <h2>Curriculum</h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span>  Prospectus <i className="bi bi-arrow-right"></i> Curriculum</span></h5>
     </div>
     
     </div>
     <div className="innerpage">
<div className="row inner_cont">
    <div className="col-lg-9 col-md-8 col-sm-12 col-12">
    <ul>
    <li>The School is affiliated to the Central Board of Secondary Education, New Delhi, and prepares the students for the "All India Secondary School Examination".</li>
    <li>The School provides for the study of the following subjects-English Language, English Literature, Hindi Language, Hindi Literature, Mathematics, Sciences, Social Sciences, Punjabi, Moral Science, General Knowledge, Art & Craft, Computers and EVS.</li>
    <li>Sports, Games, S.U.P.W., Music, Dance, Debates, Declamations, and Dramatics etc.:- all form an important part of the Curriculum.</li>
    </ul>
    <div className="clr"></div>
    <div className="row">
    <div className="col-lg-6 col-md-6 col-sm-6 col-6"><center><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/curriculum1.jpg"  alt="St. Xaviers Bathinda" className="sec_imgaes_new img-fluid" /></center></div>
    <div className="col-lg-6 col-md-6 col-sm-6 col-6"><center><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/curriculum.jpg" alt="St. Xaviers Bathinda" className="sec_imgaes_new img-fluid" /></center></div>
        </div>    
    <div className="clr"></div>
    </div>

<ProspectSidebar/>
</div>
</div>
   <Footer/>
  </>
  )
}

export default Curriculum
