import axios from "axios"
import { API_URL, getHeader } from "../config"

export const getHighlight = async() => {
    const { data } = await axios.get(API_URL + `/api/highlight/SXSBPB`);
    console.log(data)
    return data;
} 

export const getHighlightinner = async() => {
    const { data } = await axios.get(API_URL + `/api/highlight/all/SXSBPB`);
    console.log(data)
    return data;
} 

export const getNews = async() => {
    console.log("first")
    const { data } = await axios.get(API_URL + `/api/news/SXSBPB`);
    console.log("news:",data)
    return data;
} 

export const getNewsinner = async() => {
    console.log("first")
    const { data } = await axios.get(API_URL + `/api/news/all/SXSBPB`);
    console.log("news:",data)
    return data;
} 

export const getEvents = async() => {
    const { data } = await axios.get(API_URL + `/api/events/SXSBPB`);
    console.log(data)
    return data;
} 

export const getEventsinner = async() => {
    const { data } = await axios.get(API_URL + `/api/events/All/SXSBPB`);
    console.log(data)
    return data;
}

export const getBirthdays = async() => {
    const { data } = await axios.get(API_URL + `/api/birthdays/SXSBPB`);
    console.log(data)
    return data;
}

export const getGallery = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/All/SXSBPB`);
    return data;
} 
export const getLeapDay = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/All/demo`);
    return data;
} 
export const getGalleryInner = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/All/SXSBPB`);
    return data;
} 

export const getSubGallery = async(id) => {
    console.log(id)
    const { data } = await axios.get(API_URL + `/api/subGallery?id=${id}`);
    console.log(data)
    return data;
} 

export const getNotification = async() => {
    const { data } = await axios.get(API_URL + `/api/notification/SXSBPB`,getHeader());
    return data;
} 

export const getTopper = async() => {
    const { data } = await axios.get(API_URL + `/api/topper/SXSBPB`,getHeader());
    return data;
} 

export const getOnlineRegistration = async() => {
    const { data } = await axios.get(API_URL + `/api/onlineRegistration/SXSBPB`,getHeader());
    return data;
} 

export const getSliders = async() => {
    const { data } = await axios.get(API_URL + `/api/slider/SXSBPB`,getHeader());
    return data;
} 

export const getPrincipalMessage = async() => {
    const { data } = await axios.get(API_URL + `/api/PrincipalMessage/SXSBPB`, getHeader());
    return data;
} 

export const getMedia = async() => {
    const { data } = await axios.get(API_URL + `/api/media/SXSBPB`, getHeader());
    return data;
} 

export const getCircular = async() => {
    const { data } = await axios.get(API_URL + `/api/circular/all/SXSBPB`, getHeader());
    return data;
} 

export const getVideo = async() => {
    const { data } = await axios.get(API_URL + `/api/video/all/SXSBPB`, getHeader());
    return data;
} 

export const postTransferCertificate = async(name,admission) => {
    console.log(name,admission)

    const { data } = await axios.post(API_URL + `/api/tc/SXSBPB`,{name,admission},getHeader());
    return data;
}
