import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import MessageSidebar from'./MessageSidebar'
import { Link } from 'react-router-dom'
const SeniorWingCoordinatorMessage = () => {
  return (
    <>
<Header/>
     <div className="inner_slider">
     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/>  
     <div className="row bread_cumb">
     <h2>Coordinator's Message (Senior Wing)</h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span>Message <i className="bi bi-arrow-right"></i> Coordinator's Message (Senior Wing)</span></h5>
     </div>
     </div>
     <div className="clr"></div>
     <div className="innerpage">
     <div className="row inner_cont">
     <div className="col-lg-9 col-md-8 col-sm-12 col-12 message_inner">
     <article>
     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/Coordinator Senior Wing.jpg"  className="img-fluid"  alt="St. Xaviers Bathinda" />
     <h3>Coordinator's Message (Senior Wing)</h3>
     <p>‘Education is not about achieving knowledge about facts, rather its taming of the mind to think, to explore and to work out with resolute determination to reach one’s goal.’</p>
     <p>I feel proud and privileged to have been entrusted with the responsibility of being an integral part of an educational institution where management, staff members, parents and students are very assiduous, passionate, committed, energetic, competent and caring.</p> 
     <p>The system of education has become so dynamic that we need to think out of the box and think beyond a pattern. With this view, we at St. Xavier’s School Bathinda, grow day after day with the conviction to provide holistic growth to our children. Hence, our students are offered a wide range of clubs, inter- class activities, inter-house activities, sports and large variety of visual and performing art ventures that our students can participate in. We organise many co- curricular, extra-curricular activities for the students at District, State and National level so that they make persistent efforts and excel in every field of learning. The students are encouraged to partake in all the internal and external events. We strive to give every student the opportunity to express themselves through a carefully planned curriculum.</p> 
     <p>A joyful experiential learning system is one of our major aims wherein, each child is encouraged to participate wholeheartedly. We empower our students in such a manner that they can become representatives of a value-based nation.</p> 
     <p>Our dictum is that the school should not only cater to pursue academic excellence but also motivate its students to be life-long learners, critical thinkers and productive members of an ever-changing global society.</p> 
     <p>I firmly believe that proper guidance, counselling and nurturing of our students facilitate them to become intelligent, resilient, creative, imaginative, innovative, disciplined, respectful, ethical and above all sincere and dedicated.</p> 
     <p>I am convinced that the students of our school will keep on scaling great heights and keep the Xavierian flag high.
     </p> 
     <br />
     <center><p><strong>With Regards</strong></p></center>
             <h5><strong>Archana Rajput </strong></h5>
             <h4>Co-ordinator (Senior Wing)</h4>
             
     </article>
     
     <div className="clr"></div>
     </div>
     
        <MessageSidebar/>
    
     <div className="clr"></div>
     </div>
     
     <div className="clr"></div>
     </div>
     <div className="clr"></div>
   <Footer/>
  </>
  )
}

export default SeniorWingCoordinatorMessage
