import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import MessageSidebar from'./MessageSidebar'
import { Link } from 'react-router-dom'
const PrincipalMessage = () => {
  return (
    <>
<Header/>
     <div className="inner_slider">
     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/>  
     <div className="row bread_cumb">
     <h2>Principal's Message</h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span>Message <i className="bi bi-arrow-right"></i> Principal's Message</span></h5>
     </div>
     
     </div>
     <div className="clr"></div>
     <div className="innerpage">
     <div className="row inner_cont">
     <div className="col-lg-9 col-md-8 col-sm-12 col-12 message_inner">
     
     <article>
     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/principal.JPG" width="282" height="222" layout="responsive" alt="St. Xaviers Bathinda"/>
     <h3>Principal's Message</h3>
     
     <p>For me education does not mean presence of ability to read or write, it means more than that, it is to move beyond the confines of the traditional school curriculum and to teach our children to embrace the adventures of life as a gift from divinity.</p>
     <p>To teach them to become resilient to be someone unique and cuddle in love of universal principle of equality and brotherhood, to face the quest of life as the owners and not as a mere victims of destiny, to make them as ownership of who and what they want to be and script their life where they refuse the circumstances to dictate, to play a life story of success.</p>
     <p>In our institution the thrust of education lies on simple understanding - life as a special call from God, where there is no rich or poor, great or small, more or less worthy, success or failure because all have been endowed with the gift of life which is God’s own image.</p>
     <p>At the end, I reiterate that schooling in St. Xavier’s Bathinda brings the best in children through the eyes of God and through all earthly achievements.</p>
     <p>God Bless All.</p>
     
     
     <div className="clr20"></div>
     
             <h4> Fr. Cidloy Furtado</h4>
             <h5><strong>Principal</strong></h5>
     </article>
     
     <div className="clr"></div>
     </div>
     <MessageSidebar/>
     <div className="clr"></div>
     </div>
     
     <div className="clr"></div>
     </div>
     <div className="clr"></div>
   <Footer/>
  </>
  )
}

export default PrincipalMessage
