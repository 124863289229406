import React from 'react'

import { Link } from 'react-router-dom'
const Footer = () => {
  return (
     <>
     <div className="footer_sec">
     <div className="container juniorcontainer">
     <p>© Copyright 2020-2021 By St. Xavier’s School, Bathinda |  Created By <Link className="link" href="https://www.entab.in/" target="_blank"> Entab Infotech : CampusCare® </Link></p>
     </div>
     </div>
     </>
  )
}

export default Footer
