import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import { getBirthdays } from '../Service/Api';
const Birthday = () => {
    var settings = {  
      autoplay: true,
      infinite: true,
        autoplaySpeed: 2000,
        speed: 1000,
        slidesToShow:2,
        slidesToScroll: 1,
        initialSlide: 0, 
        arrows: false, 
        adaptiveHeight: true,
        responsive: [
            {
              breakpoint: 1300,
              settings: {
                slidesToShow: 2, 
              }
            },
            {
                breakpoint: 1070,
                settings: {
                  slidesToShow: 2, 
                }
              },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2, 
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow:2, 
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow:1, 
              }
            }
          ]
      };

      
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const topperData = await getBirthdays();
      setData(topperData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    console.log("events_data", data);
  }, [data]);

  const emptyArray = [
    { name: "Student Name", class: "Class", attachments: "https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/cake.png" },   
    { name: "Student Name", class: "Class", attachments: "https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/cake.png" },   
    { name: "Student Name", class: "Class", attachments: "https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/cake.png" }  
  ];

  return (
     <>
     <Slider {...settings}> 
     {data.length > 0 ? data.map((item, index) => (
            <div key={index}>
          <div className="card">
          <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} className="card-img-top" alt="St. Xaviers Bathinda" />
            <div className="card-body">
             <h5>{item.name}</h5><p>{item.class}</p></div>
         </div>  
         </div>  
        ))
        :emptyArray.map((data, index) => (
<div key={index}>
          <div className="card">
          <img src={data.attachments} className="card-img-top" alt="St. Xaviers Bathinda" />
            <div className="card-body">
             <h5>{data.name}</h5><p>{data.class}</p></div>
         </div>  
         </div>  
        ))}

           {/* <div className="card"> 
                   <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/cake.png" className="card-img-top" alt="St. Xaviers Bathinda" />
                 <div className="card-body">
                   <h5>Student Name</h5>
                   <p>Class 4</p>
                 </div>
           </div> 
            
               <div className="card"> 
                   <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/cake.png" className="card-img-top" alt="St. Xaviers Bathinda" />
                 <div className="card-body">
                   <h5>Student Name</h5>
                   <p>Class 4</p>
                 </div>
               </div> */}
               
        </Slider>
     </>
  )
}

export default Birthday
