import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import ProspectSidebar from'./ProspectSidebar'
import { Link } from 'react-router-dom'

const BookList = () => {
  return (
    <>
<Header/>
    <div className="inner_slider">
    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
    <div className="row bread_cumb">
    <h2>Book Lists</h2>
    <div className="theme-heading">
    <span className="colorW marB10"><i className="bi bi-building"></i></span>
    </div>
    <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span>  Prospectus <i className="bi bi-arrow-right"></i> Book Lists</span></h5>
    </div>
    
    </div>
    <div className="innerpage">
<div className="row inner_cont">
   <div className="col-lg-9 col-md-8 col-sm-12 col-12">
   <div className="circular_sec row">
   <div className="col-xxl-6 col-lg-12 col-md-12">
            <h3>Session 2024-25</h3>   
                <div className="row">
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 fullwidth">
            <article><Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/pdf/BOOKLIST-LKG-III-24.pdf" target="_blank"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/circular.png" width="450" height="300" layout="responsive" alt="St. Xaviers Bathinda"/>
              <h5>2024 - 25</h5>
              <h4>BOOKLIST LKG-III</h4>
              </Link></article>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 fullwidth">
            <article><Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/pdf/BOOKLIST-IV-X-24.pdf" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/circular.png" width="450" height="300" layout="responsive" alt="St. Xaviers Bathinda"/>
              <h5>2024 - 25</h5>
              <h4>BOOKLIST IV-X</h4>
              </Link></article>
          </div> 
          </div>     
            </div>
   <div className="col-xxl-6 col-lg-12 col-md-12">
       <h3>Session 2023-24</h3>	
           <div className="row">
     <div className="col-12 col-sm-6 col-md-6 col-lg-6 fullwidth">
       <article><Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/pdf/BOOKLIST-LKG-IV-23.pdf" target="_blank"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/circular.png" width="450" height="300" layout="responsive" alt="St. Xaviers Bathinda"/>
         <h5>2023 - 24</h5>
         <h4>BOOKLIST LKG-IV</h4>
         </Link></article>
     </div>
     <div className="col-12 col-sm-6 col-md-6 col-lg-6 fullwidth">
       <article><Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/pdf/BOOKLIST-V-X-23.pdf" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/circular.png" width="450" height="300" layout="responsive" alt="St. Xaviers Bathinda"/>
         <h5>2023 - 24</h5>
         <h4>BOOKLIST V-X</h4>
         </Link></article>
     </div> 
     </div>		
       </div>
       <div className="col-xxl-6 col-lg-12 col-md-12">
       <h3>Session 2022-23</h3>	
       <div className="row">		
     <div className="col-12 col-sm-6 col-md-6 col-lg-6 fullwidth">
       <article><Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/pdf/BOOKLIST-LKG-III.pdf" target="_blank"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/circular.png" width="450" height="300" layout="responsive" alt="St. Xaviers Bathinda"/>
         <h5>2022 - 23</h5>
         <h4>BOOKLIST LKG-III</h4>
         </Link></article>
     </div>
     <div className="col-12 col-sm-6 col-md-6 col-lg-6 fullwidth">
       <article><Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/pdf/BOOKLIST-IV-X.pdf" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/circular.png" width="450" height="300" layout="responsive" alt="St. Xaviers Bathinda"/>
         <h5>2022 - 23</h5>
         <h4>BOOKLIST IV-X</h4>
         </Link></article>
     </div>  
     </div>
</div>
<div className="col-xxl-6 col-lg-12 col-md-12">
       <h3>Session 2021-22</h3>	
       <div className="row">		
     <div className="col-12 col-sm-6 col-md-6 col-lg-6 fullwidth">
       <article><Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/pdf/BOOKLIST-LKG-IV.pdf" target="_blank"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/circular.png" width="450" height="300" layout="responsive" alt="St. Xaviers Bathinda"/>
         <h5>2021 - 22</h5>
         <h4>BOOKLIST LKG-IV</h4>
         </Link></article>
     </div>
     <div className="col-12 col-sm-6 col-md-6 col-lg-6 fullwidth">
       <article><Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/pdf/BOOKLIST-V-IX.pdf" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/circular.png" width="450" height="300" layout="responsive" alt="St. Xaviers Bathinda"/>
         <h5>2021 - 22</h5>
         <h4>BOOKLIST V-IX</h4>
         </Link></article>
     </div>  
     </div>          
</div>
<div className="col-xxl-6 col-lg-12 col-md-12">
       <h3>Session 2020-21</h3>
       <div className="row">
     <div className="col-12 col-sm-6 col-md-6 col-lg-6 fullwidth">
       <article><Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/pdf/BKL_LKG_III.pdf" target="_blank"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/circular.png" width="450" height="300" layout="responsive" alt="St. Xaviers Bathinda"/>
         <h5>2020 - 21</h5>
         <h4>Book List LKG - III</h4>
         </Link></article>
     </div>
     <div className="col-12 col-sm-6 col-md-6 col-lg-6 fullwidth">
       <article><Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/pdf/BKL_4_12.pdf" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SXSBPB/public/Images/circular.png" width="450" height="300" layout="responsive" alt="St. Xaviers Bathinda"/>
         <h5>2020 - 21</h5>
         <h4>Book List IV - XII</h4>
         </Link></article>
     </div>
     <div className="clr"></div>
     </div>
     </div>
   </div>
   <div className="clr"></div>
 </div>

<ProspectSidebar/>

</div>
</div>
  <Footer/>
  </>
  )
}

export default BookList
