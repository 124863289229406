import React from 'react'
import { NavLink } from 'react-router-dom'
const AboutSidebar = () => {
  return (
    <>
    <div className="col-lg-3 col-md-4 col-sm-12 col-12">
    <div className="inner_menu">
    <h3>Menu Choice</h3>
    <ul id="sidebar">
    <li><NavLink  to="/Highlights">Notice Board </NavLink></li>
    <li><NavLink  to="  ">Announcements </NavLink></li>
    <li><NavLink  to="/Teachers">Teachers  </NavLink></li>
    <li><NavLink  to="/Students"> Students  </NavLink></li>
    <li><NavLink  to="/Magazine">Magazines </NavLink></li>
    <li><NavLink  id="PhotoGallery" to="/PhotoGallery">Photo Gallery </NavLink></li>
    <li><NavLink to="/VideoGallery">Video Gallery </NavLink></li>
    <li><NavLink to="/Memories">Memories </NavLink></li>
    <li><NavLink to="/MediaGallery">Media Gallery </NavLink></li>
    <li><NavLink to="/VoiceSms">Voice SMS </NavLink></li>
    <li><NavLink to="/VirtualTour">Virtual Tour </NavLink></li>
    </ul>
    <div className="clr"></div>
    </div>
    <div className="important_menu">
    <h3>Important Menu</h3>
    <ul>
    <li><NavLink to="/VirtualTour"><i className="fas fa-camera" aria-hidden="true"></i> 360<sup>o</sup></NavLink></li>
    <li><NavLink to="/ParentPortal"><i className="fa fa-newspaper" aria-hidden="true"></i> Portal Help?</NavLink></li>
    <li><NavLink to="/ErpSoftware"><i className="fas fa-desktop" aria-hidden="true"></i> ERP Software</NavLink></li>
    <li><NavLink to="/Junior/Default"><i className="fas fa-child" aria-hidden="true"></i> Kid's Corner</NavLink></li>
    <li><NavLink to="/Achievements"><i className="fa fa-trophy" aria-hidden="true"></i> Achievements</NavLink></li>
    <li><NavLink to="/Speech"><i className="fa fa-microphone" aria-hidden="true"></i> Speech</NavLink></li>
    </ul>
    <div className="clr"></div>
    </div>
    
    </div>
    </>
  )
}

export default AboutSidebar
